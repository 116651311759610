import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, message as antMessage } from 'antd';
import { useSelector } from 'react-redux';
import { Dna } from "react-loader-spinner";

import NotificationTable from "../../components/notification/NotificationTable";
import NotificationButton from '../../components/notification/NotificationButton';
import NotificationModel from '../../components/notification/NotificationModel';

import { DEV_URL } from '../../configuration/config';

import './NotificationScreen.css';

const NotificationScreen = () => {
    const user = useSelector(state => state.auth.user);
    const [userType, setUserType] = useState('Admin');
    const [notifications, setNotification] = useState([]);
    const [isCreateMessageModelOpen, setIsCreateMessageModelOpen] = useState(false);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [notificationSendTo, setNotificationSendTo] = useState(0);
    const [notificationSendUserList, setNotificationSendUserList] = useState([]);
    const [notificationSelectedUser, setNotificationSelectedUser] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const fetchAllNotifications = async () => {
        setIsLoading(true);

        try{
            const { data } = await axios.get(`${DEV_URL}/api/notification?user=${encodeURIComponent(user.displayName)}`);
            const notifications = data.notifications;
            const notificationToFormat = [];

            notifications.forEach(noti => {
                const formatedNotification = {
                    id: noti.id,
                    time: noti.time,
                    from: noti.from,
                    to: noti.to,
                    subject: noti.subject,
                    description: noti.message,
                    isRead: noti.read,
                }
                notificationToFormat.push(formatedNotification);
            });
            setNotification(notificationToFormat);

        }catch (error){
            console.log(error);
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 2000)
    }

    useEffect(() => {
        if(user.type === 1){
            setUserType('Company')
        }else if(user.type === 2){
            setUserType('Investor');
        }else if(user.type === 3){
            setUserType('Admin');
        }else{
            setUserType('Admin');
        }

        fetchAllNotifications();
    },[]);

    useEffect(() => {
        setIsLoading(true);

        const fetchUserNames = async () => {
            try{
                if(notificationSendTo === 2){
                    // company
                    const { data } = await axios.get(`${DEV_URL}/api/user/company/names`);
                    setNotificationSendUserList(data.companies);
                }else if(notificationSendTo === 3){
                    // investor
                    const { data } =  await axios.get(`${DEV_URL}/api/user/investor/names`);
                    setNotificationSendUserList(data.investors);
                }
            }catch(error){
                console.log(error);
            }
        }
        fetchUserNames();

        setIsLoading(false);
    },[notificationSendTo])

    const readNotification = async (id) => {
        let updatedNotifications = [...notifications];
        let changedNotification = updatedNotifications.findIndex(notifi => notifi.id === id);
        updatedNotifications[changedNotification].isRead = true;
        setNotification(updatedNotifications);

        try{
            await axios.post(`${DEV_URL}/api/notification/markAsReaded`, {
                readName: user.displayName,
                notificationId: id,
            });
        }catch (error){
            console.log(error);
        }
    }

    const createNotification = () => {
        setIsCreateMessageModelOpen(true);
    }

    const cancelCreateNotifications = () => {
        setIsCreateMessageModelOpen(false);
    }

    const createNotifications = async () => {
        setIsLoading(true);

        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();
        const formattedMinutes = minutes.toString().padStart(2, '0');

        const subjectValid = subject.length > 2;
        const messageValid = message.length > 2;
        const sendToValid = notificationSendTo !== 0;
        const notificationSelectedValid = notificationSendTo === 1 ? true : notificationSelectedUser.length !== 0;

        if(subjectValid && messageValid && sendToValid && notificationSelectedValid){
            try{
                if(notificationSendTo === 1){
                    try{
                        await axios.post(`${DEV_URL}/api/notification/create`, {
                            time: `${hours}.${formattedMinutes}`,
                            from: user.displayName,
                            to: 'All',
                            subject: subject,
                            message: message
                        });
                    }catch (error){
                        console.log(error);
                    }
                }else{
                    try{
                        await axios.post(`${DEV_URL}/api/notification/create`, {
                            time: `${hours}.${formattedMinutes}`,
                            from: user.displayName,
                            to: notificationSelectedUser,
                            subject: subject,
                            message: message
                        });
                    }catch (error){
                        console.log(error)
                    }
                }

                setSubject('');
                setMessage('');
                setNotificationSendTo(0);
                setNotificationSelectedUser('');

            }catch(error){
                console.log(error)
            }
    
            setIsCreateMessageModelOpen(false);
            antMessage.success('Notification send');
        }else{
            antMessage.error('enter values before send notification');
        }

        fetchAllNotifications();

        setIsLoading(false)
    }

    const setMessageSubject = (text) => {
        setSubject(text);
    }

    const setMessageText = (text) => {
        setMessage(text);
    }

    const setNotificationSendToValue = (value) => {
        setNotificationSendTo(value);
        setNotificationSendUserList([]);
        setNotificationSelectedUser('');

    }

    const setNotificationSelectOneUser = (value) => {
        setNotificationSelectedUser(value);
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'notification-screen'}>
            <Modal title="Create Message" visible={isCreateMessageModelOpen} onOk={createNotifications} 
                onCancel={cancelCreateNotifications} className={'custom-model'} okButtonProps={{className: 'custom-model-ok-button'}}>
                <NotificationModel subject={subject} message={message} setMessageSubject={setMessageSubject}
                 setMessageText={setMessageText} notificationSendTo={notificationSendTo} 
                 setNotificationSendToValue={setNotificationSendToValue} 
                 notificationSendUsers={notificationSendUserList} 
                 notificationSelectedUser={notificationSelectedUser} 
                 setNotificationSelectOneUser={setNotificationSelectOneUser}/>
            </Modal>
            <div className={'notification-header'}>
                <p className={'notification-title'}>Notifications</p>
            </div>
            <div className={'notification-divider'}></div>
            <div className={'notification-container'}>
                {userType === 'Admin' && <NotificationButton handleCreateNotification={createNotification}/>}
                <NotificationTable notifications={notifications} userType={userType} readNotification={readNotification}/>
            </div>
        </div>
    )
}

export default NotificationScreen;