import React, { useState, useEffect } from 'react';
import { Modal, message, Checkbox } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { Dna } from "react-loader-spinner";
import axios from "axios";

import AdminScheduleTable from '../../components/schedule/AdminScheduleTable';
import CustomButton from "../../components/common/button/CustomButton";
import MeetingSortingBar from "../../components/schedule/MeetingSortingBar";
import { DEV_URL } from '../../configuration/config';

import './ScheduleScreen.css';
import InvestorAcceptMeetingModel from "../../components/schedule/InvestorAcceptMeetingModel";

const initialAvailableTimes = ['16.00', '16.15', '16.30', '16.45', '17.00', '17.15', '17.30', '17.45', '18.00'];

const meetingTypeList = ['Virtual', 'Physical'];

const AdminScheduleScreen = () => {
    let user = useSelector(state => state.auth.user);

    const [isAddClick, setIsAddClick] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteEvent, setDeleteEvent] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [selectedDate, setSelectedDate] = useState('2024-06-25');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectCompany, setSelectCompany] = useState('');
    const [selectInvestor, setSelectInvestor] = useState('');
    const [noteToInvestor, setNoteToInvestor] = useState('');
    const [noteToCompany, setNoteToCompany] = useState('');
    const [meetingType, setMeetingType] = useState('Physical');
    const [isOnEvent, setIsOnEvent] = useState(false);

    const [availableCompanyList, setAvailableCompanyList] = useState([]);
    const [availableInvestorList, setAvailableInvestorList] = useState([]);
    const [availableTimeList, setAvailableTimeList] = useState([]);

    const [isShowModel, setIsShowModel] = useState(false);
    const [acceptMeetingType, setAcceptMeetingType] = useState('Physical');
    const [acceptMeetingLocation, setAcceptMeetingLocation] = useState('Event');
    const [acceptMeetingId, setAcceptMeetingId] = useState('');

    const [allCompanies, setAllCompanies] = useState([]);
    const [allInvestors, setAllInvestors] = useState([]);

    const today = new Date().toISOString().split('T')[0];

    const [events, setEvents] = useState([]);

    const fetchAllScheduledMeetings = async () => {
        setIsLoading(true);

        if(user.id !== undefined){
            try{
                const { data } = await axios.get(`${DEV_URL}/api/meetings/user/${user.id}`);
                const meetings = data.meetings;

                const finalMeetingList = [];
                for(let meeting of meetings){
                    let tempMeeting = {
                        id: meeting.id,
                        status: meeting.status,
                        day: meeting.day,
                        startingTime: meeting.startingTime,
                        endingTime: meeting.endingTime,
                        company: meeting.company.companyName,
                        investor: `${meeting.investor.firstName} ${meeting.investor.lastName}`,
                        room: meeting.room,
                        roomType: meeting.roomType
                    }
                    finalMeetingList.push(tempMeeting);
                }
                setEvents(finalMeetingList);
            }catch (error){
                console.log(error);
            }
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 500)
    }

    const filterPastTimes = () => {
        const today = new Date().toISOString().split('T')[0];
        const currentTime = new Date().getHours();

        if(selectedDate === today){
            let currentAvailableTimes = initialAvailableTimes;
            currentAvailableTimes = currentAvailableTimes.filter(time => parseFloat(time) > currentTime);
            setAvailableTimeList(currentAvailableTimes);
        }else{
            setAvailableTimeList(initialAvailableTimes);
        }
    }

    const fetchAllInvestorsAndCompanies = async () => {
        try {
            const { data: investorData } = await axios.get(`${DEV_URL}/api/investors`);
            setAllInvestors(investorData?.investors);

            const { data: companyData } = await axios.get(`${DEV_URL}/api/companies`);
            setAllCompanies(companyData?.companies);
        }catch (err){
            console.log(err);
        }
    }

    useEffect(() => {
        fetchAllInvestorsAndCompanies();
    }, []);

    useEffect(() => {
        fetchAllScheduledMeetings();
    },[selectedDate, user]);

    useEffect(() => {
        filterPastTimes();
    },[selectedDate]);

    const deleteEventHandler = (eve) => {
        setIsModalVisible(true);
        setDeleteEvent(eve);
    }

    const handleOk = async () => {
        try{
            await axios.delete(`${DEV_URL}/api/meetings/${deleteEvent.id}`);
        }catch (error){
            console.log(error);
        }

        fetchAllScheduledMeetings();

        setIsModalVisible(false);
        setDeleteEvent({});
        setIsAddClick(false);
    }

    const handleCancel = () => {
        setIsAddClick(false);
        setIsModalVisible(false);
    };

    const handleTimeChange = async (e) => {
        setSelectedTime(e.target.value);
        // need to fetch available companies
        // need to fetch available investors
        // need to fetch available rooms

    }

    const isDateAndTimeSelected = () => {
        return !(selectedDate === '' || selectedTime === '');
    }

    const isDateSelected = () => {
        return selectedDate !== '';
    }

    const handleNoteToInvestor = (e) => {
        setNoteToInvestor(e.target.value);
    }

    const handleNoteToCompany = (e) => {
        setNoteToCompany(e.target.value);
    }

    const handleSetIsOnEvent = (e) => {
        setIsOnEvent(e.target.checked);
    }

    useEffect(() => {
        setIsLoading(true);

        const fetchAvailableCompanies = async () => {
            try{
                const { data } = await axios.get(`${DEV_URL}/api/meetings/availability/company?time=${encodeURIComponent(selectedTime)}&day=${encodeURIComponent(selectedDate)}`);
                setAvailableCompanyList(data.availableCompanies);
            }catch (error){
                console.log(error);
            }
        }

        const fetchAvailableInvestors = async () => {
            try{
                const { data } = await axios.get(`${DEV_URL}/api/meetings/availability/investor?time=${encodeURIComponent(selectedTime)}&day=${encodeURIComponent(selectedDate)}`);
                setAvailableInvestorList(data.availableInvestors);
            }catch (error){
                console.log(error);
            }
        }

        fetchAvailableCompanies();
        fetchAvailableInvestors();

        setIsLoading(false)
    },[selectedTime, selectedDate]);

    const handleCompanyChange = (e) => {
        setSelectCompany(e.target.value);
    }

    const handleInvestorChange = (e) => {
        setSelectInvestor(e.target.value);
    }

    const handleMeetingTypeChange = (e) => {
        setMeetingType(e.target.value);
    }

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const addScheduleClick = async () => {
        if(isAddClick){
            const dateValid = selectedDate.length > 0;
            const startTimeValid = selectedTime.length > 0;
            const companyValid = selectCompany.length > 0;
            const investorValid = selectInvestor.length > 0;

            if(startTimeValid && companyValid && investorValid && dateValid){
                const [hours, minutes] = selectedTime.split(".").map(Number);
                let newHours = hours;
                let newMinutes = (minutes + 15) % 60;

                if (newMinutes === 0) {
                    newHours = (newHours + 1) % 24;
                }

                let endingTime = `${newHours}.${newMinutes.toString().padStart(2, "0")}`;

                try{
                    await axios.post(`${DEV_URL}/api/meetings/schedule?user=Admin`, {
                        day: selectedDate,
                        startingTime: selectedTime,
                        endingTime: endingTime,
                        companyId: selectCompany,
                        investorId: selectInvestor,
                        noteToCompany: noteToCompany,
                        noteToInvestor: noteToInvestor,
                        meetingType: meetingType,
                        locationType: 'Event'
                    });
                }catch (error){
                    console.log(error);
                }

                fetchAllScheduledMeetings();

                setSelectInvestor('');
                setSelectCompany('');
                setSelectedTime('');
                setSelectedDate('');
                setNoteToCompany('');
                setNoteToInvestor('');
                setMeetingType('Physical');
                setIsOnEvent(false);
                setIsAddClick(false);
            }else {
                message.error('select fields before adding schedule');
            }
        }else{
            setIsAddClick(true);
        }
    }

    const declineRequest = async (id) => {
        try{
            await axios.put(`${DEV_URL}/api/meetings/reject/${id}`,{
                message: ''
            });
        }catch (error){
            console.log(error);
        }
        await fetchAllScheduledMeetings();
    }

    const cancelSortingHandler = async () => {
        await fetchAllScheduledMeetings();
    }

    const sortingHandler = async (companyId, investorId) => {
        try {
            const { data } = await axios.get(`${DEV_URL}/api/meetings/filter?investor=${investorId}&company=${companyId}`);

            const finalMeetingList = [];
            for(let meeting of data?.meetings){
                let tempMeeting = {
                    id: meeting.id,
                    status: meeting.status,
                    day: meeting.day,
                    startingTime: meeting.startingTime,
                    endingTime: meeting.endingTime,
                    company: meeting.company.companyName,
                    investor: `${meeting.investor.firstName} ${meeting.investor.lastName}`,
                    room: meeting.room,
                    roomType: meeting.roomType
                }
                finalMeetingList.push(tempMeeting);
            }

            if(data?.meetings){
                setEvents(finalMeetingList);
            }
        }catch (error){
            console.log(error);
        }
    }

    const handleChangeAcceptMeetingType = (type) => {
        setAcceptMeetingType(type);
    }

    const handleChangeAcceptMeetingLocation = (type) => {
        setAcceptMeetingLocation(type)
    }

    const clickCancelInModelHandler = () => {
        setIsShowModel(false);
        setAcceptMeetingId('');
        setNoteToCompany('');
        setAcceptMeetingType('Physical');
        setAcceptMeetingLocation('Event');
    }

    const clickOKInModelHandler = async () => {
        try{
            await axios.put(`${DEV_URL}/api/meetings/accept/${acceptMeetingId}`,{
                room: acceptMeetingType,
                roomType: 'Event',
                noteToCompany: noteToCompany
            });

            setAcceptMeetingId('');
            setAcceptMeetingType('Physical');
            setAcceptMeetingLocation('Event');
            setNoteToCompany('');
            setIsShowModel(false);
        }catch (error){
            console.log(error);
        }
        await fetchAllScheduledMeetings();
    }

    const acceptRequest = async (meetingId) => {
        setIsShowModel(true);
        setAcceptMeetingId(meetingId);
    }

    const handleGenerateExcel = async () => {
        try{
            const response = await axios.get(`${DEV_URL}/api/meetings/generate`,{
                responseType: 'blob'
            });

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'meetings.xlsx';
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }catch (error){
            console.log(error);
        }
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'schedule-screen'}>
            <Modal title="Confirm Delete" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure you want to delete this event?</p>
            </Modal>
            {isShowModel && <InvestorAcceptMeetingModel clickCancel={clickCancelInModelHandler} clickOk={clickOKInModelHandler}
                                                        isOpen={isShowModel} acceptMeetingType={acceptMeetingType}
                                                        acceptMeetingLocation={acceptMeetingLocation}
                                                        changeAcceptMeetingLocation={handleChangeAcceptMeetingLocation}
                                                        changeAcceptMeetingType={handleChangeAcceptMeetingType} note={noteToCompany}
                                                        changeNote={handleNoteToCompany}/>}
            <div className={'schedule-header'}>
                <p className={'schedule-title'}>Schedule</p>
            </div>
            <div className={'schedule-divider'}></div>
            <CustomButton buttonClick={handleGenerateExcel} title={'Generate Excel'} color={'#000000'}/>
            <div className={'schedule-sort-bar-container'}>
                <MeetingSortingBar investors={allInvestors} companies={allCompanies} cancelSort={cancelSortingHandler}
                                   handleSortClick={sortingHandler}/>
            </div>
            <div className={'schedule-container'}>
                <AdminScheduleTable events={events} onDeleteClick={deleteEventHandler} declineRequest={declineRequest}
                                    acceptRequest={acceptRequest}/>
            </div>
            {isAddClick && <div>
                <div className={'new-schedule-meeting-title-container'}>
                    <p className={'new-schedule-meeting-title'}>New Meeting</p>
                </div>
                <div className={'new-admin-schedule-container'}>
                    {/*<div className={'custom-date-selector-container'}>
                        <input type="date" id="date" name="date" className={'custom-date-selector'} value={selectedDate}
                               onChange={handleDateChange} min={today}/>
                    </div>*/}

                    <select value={selectedTime} onChange={handleTimeChange} placeholder={'Time'} className="custom-select"
                            disabled={!isDateSelected()}>
                        <option value="" disabled hidden>Time</option>
                        {availableTimeList.map((time, index) => {
                            return(
                                <option value={time} className={'custom-option'} key={index}>{time}</option>
                            )
                        })}
                    </select>

                    <select value={selectCompany} onChange={handleCompanyChange} placeholder={'Company'} className="custom-select"
                            disabled={!isDateAndTimeSelected()}>
                        <option value="" disabled hidden>Company</option>
                        {availableCompanyList.map((company, index) => {
                            return(
                                <option value={company.id} key={index} className={'custom-option'}>
                                    {company.companyName}
                                </option>
                            )
                        })}
                    </select>

                    <select value={selectInvestor} onChange={handleInvestorChange} placeholder={'Investor'} className="custom-select"
                            disabled={!isDateAndTimeSelected()}>
                        <option value="" disabled hidden>Investor</option>
                        {availableInvestorList.map((investor, index) => {
                            return(
                                <option value={investor.id} className={'custom-option'} key={index}>
                                    {investor.firstName}
                                </option>
                            )
                        })}
                    </select>

                    <select value={meetingType} onChange={handleMeetingTypeChange} placeholder={'Meeting Type'}
                            className="custom-select" disabled={!isDateAndTimeSelected()}>
                        <option value="" disabled hidden>Physical</option>
                        {meetingTypeList.map((meetingType, index) => {
                            return(
                                <option value={meetingType} className={'custom-option'} key={index}>
                                    {meetingType}
                                </option>
                            )
                        })}
                    </select>

                </div>
                <div className={'admin-new-schedule-note-container'}>
                    <textarea value={noteToInvestor} onChange={handleNoteToInvestor} placeholder={'* Note to Investor '}
                              className={'new-schedule-note'}>
                    </textarea>
                    <textarea value={noteToCompany} onChange={handleNoteToCompany} placeholder={'* Note to Company '}
                              className={'new-schedule-note'}>
                        </textarea>
                </div>
                {/*meetingType !== 'Virtual' && <div className={'admin-new-schedule-checkbox-container'}>
                    <Checkbox checked={isOnEvent} className="schedule-custom-checkbox" onChange={handleSetIsOnEvent}>
                        Is this meeting going to take place during the event?
                    </Checkbox>
                </div>*/}
            </div>}
            <div className={'schedule-add-button'} onClick={addScheduleClick}>
                <FormOutlined className={'schedule-icon'}/>
                <p className={'schedule-add-button-text'}>{!isAddClick ? 'Add Schedule' : 'Save Changes'}</p>
            </div>
        </div>
    )
}

export default AdminScheduleScreen;