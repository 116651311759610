import React from "react";
import { Alert } from "antd";

import './CustomAlert.css';

const CustomAlert = ({description}) => {
    return(
        <Alert
            className="cus-alert"
            message="Error"
            description={ description }
            type="error"
            showIcon
            closable
        />
    )
}

export default CustomAlert;