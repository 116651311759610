import React from "react";

import './MeetingTypeSelectionButton.css';

 const MeetingTypeSelectionButton = ({ selected, title, clickHandler }) => {
     return(
         <div onClick={clickHandler} className={`meeting-type-select-button ${selected ? 'meeting-type-select-button-select' 
             : 'meeting-type-select-button-not-select'}`}>
             <p className={`meeting-type-select-button-text ${selected ? 'meeting-type-select-button-select-text'
                 : 'meeting-type-select-button-not-select-text'}`}>{title}</p>
         </div>
     )
 }

 export default MeetingTypeSelectionButton;