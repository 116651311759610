import React, { useState, useEffect } from "react";
import {Routes, Route, Outlet} from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { useDispatch } from 'react-redux';

import LoginScreen from "./screens/login/LoginScreen";
import RegisterScreen from "./screens/register/RegisterScreen";
import RegisterAsInvestor from "./screens/register/RegisterAsInvestor";
import RegisterAsCompany from "./screens/register/RegisterAsCompany";
import Dashboard from "./screens/dashboard/Dashboard";
import SignUpScreen from "./screens/signup/SignUpScreen";
import CalanderScreen from "./screens/calander/CalanderScreen";
import ScheduleScreen from "./screens/schedule/ScheduleScreen";
import ProfileScreen from "./screens/profile/profileScreen";
import NotificationScreen from "./screens/notification/NotificationScreen";
import InvestorScreen from "./screens/investor/InvestorScreen";
import CompanyScreen from "./screens/company/CompanyScreen";
import SingleCompanyDetailScreen from "./screens/company/SingleCompanyDetailScreen";
import AdminScheduleScreen from "./screens/schedule/AdminScheduleScreen";
import SingleInvestorDetailScreen from "./screens/investor/SingleInvestorDetailScreen";
import ForgotPasswordScreen from "./screens/login/ForgotPasswordScreen";
import CustomNavbar from "./components/common/navbar/CustomNavbar";
import DisclaimerScreen from "./screens/signup/DisclaimerScreen";
import TemporyLogicScreen from './screens/login/TemporyLogicScreen';
import CustomTopBar from "./components/common/topbar/CustomTopBar";

import LoginReducer from './store/reducers/LoginReducer';

import { loginUser } from './store/actions/LoginAction';

import './App.css';


const rootReducer = combineReducers({
    auth: LoginReducer
});

const store = createStore(rootReducer);

function App() {
  return (
    <Provider store={store}>
    <div className="App">
      <Routes>
          <Route path={'/temp-log-in'} element={<TemporyLogicScreen/>}></Route>
          <Route path={'/log-in'} element={<LoginScreen/>}></Route>
          <Route path={'/sign-up'} element={<RegisterScreen/>} exact></Route>
          <Route path={'/disclaimer'} element={<DisclaimerScreen/>}></Route>
          <Route path={'/sign-up-as'} element={<SignUpScreen/>}/>
          <Route path={'/register/investor'} element={<RegisterAsInvestor/>}/>
          <Route path={'/register/company'} element={<RegisterAsCompany/>}/>
          <Route path={'/forgot-password'} element={<ForgotPasswordScreen/>}/>

          <Route path="/" element={<MainLayout />}>
              <Route element={<Dashboard/>} index={true}/>
              <Route path={'/calander'} element={<CalanderScreen/>}/>
              <Route path={'/schedule'} element={<ScheduleScreen/>}/>
              <Route path={'/profile'} element={<ProfileScreen/>}/>
              <Route path={'/notification'} element={<NotificationScreen/>}/>
              <Route path={'/investor'} element={<InvestorScreen/>}/>
              <Route path={'/investor/:id'} element={<SingleInvestorDetailScreen/>}/>
              <Route path={'/company'} exact element={<CompanyScreen/>}/>
              <Route path={'/company/:id'} element={<SingleCompanyDetailScreen/>}/>
              <Route path={'/admin-schedule'} element={<AdminScheduleScreen/>}/>
          </Route>
      </Routes>
    </div>
    </Provider>
  );
}

function MainLayout() {
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let userLocal = localStorage.getItem('user');
        let userObj = JSON.parse(userLocal);
        if(userObj){
            dispatch(loginUser(userObj));
        }
    },[]);

    const sideBarToggle = () => {
        setSideBarOpen(!sideBarOpen);
    }

    const sideBarClose = () => {
        setSideBarOpen(false);
    }

    return (
        <div className="layout">
            <div style={{height: '80px'}}>
                <CustomTopBar sideBarToggle={sideBarToggle}/>
            </div>
            <div className={'layout-container'}>
                <div className={'nav-container'}>
                    <CustomNavbar/>
                </div>
                {<nav className="top-nav">
                    {sideBarOpen && <CustomNavbar customClickHandler={sideBarToggle}/>}
                </nav>}
                <div className={'content'} onClick={sideBarClose}>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default App;
