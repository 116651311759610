import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import { Dna } from "react-loader-spinner";

import DashboardTypeSelectButton from "../../components/dashboard/DashboardTypeSelectButton";
import NotRegisteredYet from '../../components/dashboard/NotRegisteredYet';
import NotApprovedYet from "../../components/dashboard/NotApprovedYet";
import OverallMatches from "../../components/dashboard/OverallMatches";
import CustomBarChart from "../../components/dashboard/CustomBarChart";
import { DEV_URL  } from '../../configuration/config';

import './DashBoard.css';

const Dashboard = () => {
    const user = useSelector(state => state.auth.user);

    const [userType, setUserType] = useState('Admin');
    const [adminSelectType, setAdminSelectType] = useState('Company');
    const [isLoading, setIsLoading] = useState(false);

    const [companyIndustries, setCompanyIndustries] = useState([]);
    const [companyindustryNumber, setCompanyIndustryNumber] = useState([]);
    const [investorIndustries, setInvestorIndustries] = useState([]);
    const [investorindustryNumber, setInvestorIndustryNumber] = useState([]);
    const [totalNumberOfCompanies, setTotalNumberOfCompanies] = useState(0);
    const [totalNumberOfInvestors, setTotalNumberOfInvestors] = useState(0);
    const [initCompanyTypesWithNumber, setInitCompanyTypesWithNumber] = useState([]);
    const [initInvestorTypeWithNumber, setInitInvestorTypeWithNumber] = useState([]);
    const [overallMatches, setOverallMatches] = useState([]);

    const [industryToType, setIndustryToType] = useState([]);
    const [displayType, setDisplayType] = useState('');

    //console.log(industryToType);

    useEffect(() => {
        setIsLoading(true);

        async function fetchData() {
            await fetchDataAsCompany();
            await fetchDataAsInvestor();
        }

        async function fetchData2() {
            await fetchDataAsInvestor();
            await fetchDataAsCompany();
        }
        
        if(user.type === 1){
            setUserType('Company');
            fetchData2();
        }else if(user.type === 2){
            setUserType('Investor');
            fetchData();
        }else if(user.type === 3){
            setUserType('Admin');
            fetchData();
        }else{
            setUserType('Admin');
            fetchData();
        }

        fetchMatches();

        if(userType === 'Company'){
            setDisplayType('Investor');
            setIndustryToType(initInvestorTypeWithNumber);
        }else if(userType === 'Investor'){
            setDisplayType('Company');
            setIndustryToType(initCompanyTypesWithNumber);
        }else if(userType === 'Admin' && adminSelectType === 'Company'){
            setDisplayType('Company');
            setIndustryToType(initCompanyTypesWithNumber);
        }else if(userType === 'Admin' && adminSelectType === 'Investor'){
            setDisplayType('Investor');
            setIndustryToType(initInvestorTypeWithNumber);
        }

        setTimeout(() => {
            setIsLoading(false);
        },2000);
    },[userType, adminSelectType, totalNumberOfCompanies, totalNumberOfInvestors]);

    const fetchDataAsInvestor = useCallback(async () => {
        try{
            const response = await axios.get(`${DEV_URL}/api/dashboard/investors/total`);
            setTotalNumberOfInvestors(response.data.count);

            const response2 = await axios.get(`${DEV_URL}/api/dashboard/investors/categories`);
            setInvestorIndustries(response2.data.industries);
            setInvestorIndustryNumber(response2.data.industryNumber);

            const response3 = await axios.get(`${DEV_URL}/api/dashboard/investors/type?category=All`);
            const responseData = [];
            for(let res of response3.data){
                if(res.value !== 0){
                    responseData.push(res);
                }
            }
            setInitInvestorTypeWithNumber(responseData);

            if(user.type === 1){
                setIndustryToType(responseData);
            }else if(user.type === 3 && adminSelectType === 'Investor'){
                setIndustryToType(responseData);
            }

        }catch (error){
            console.log(error);
        }
    },[userType]);

    const fetchDataAsCompany = async () => {
        try{
            const response = await axios.get(`${DEV_URL}/api/dashboard/companies/total`);
            setTotalNumberOfCompanies(response.data.count);

            const response2 = await axios.get(`${DEV_URL}/api/dashboard/companies/categories`);
            setCompanyIndustries(response2.data.industries);
            setCompanyIndustryNumber(response2.data.industryNumber);

            const response3 = await axios.get(`${DEV_URL}/api/dashboard/companies/type?category=All`);
            const responseData = [];
            for(let res of response3.data){
                if(res.value !== 0){
                    responseData.push(res);
                }
            }
            setInitCompanyTypesWithNumber(responseData);

            if(user.type === 2){
                setIndustryToType(responseData);
            }else if(user.type === 3 && adminSelectType === 'Company'){
                setIndustryToType(responseData);
            }

        }catch (error){
            console.log(error);
        }
    }

    const fetchMatches = async () => {
        if(user.id){
            try{
                const { data } = await axios.get(`${DEV_URL}/api/matching/${user.id}`);
                setOverallMatches(data.list);
            }catch (error){
                console.log(error);
            }
        }
    }

    let COLORS = [];

    if(userType === 'Company'){
        COLORS = ['#371a89', '#C3B1E1', '#211845', '#4d009a'];
    }else if(userType === 'Investor'){
        COLORS = ['#3287d6','#211845','#003e9a','#87CEEB'];
    }else if(userType === 'Admin'){
        COLORS = ['#0d7b92','#004756','#042a32','#93C572'];
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
                                       cx, cy, midAngle, innerRadius, outerRadius, percent, index, name
                                   }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {name}
            </text>
        );
    };

    const handleCompanyIndustryClick = async (type) => {
        try {
            const { data } = await axios.get(`${DEV_URL}/api/dashboard/companies/type?category=${encodeURIComponent(type)}`);
            const responseData = [];
            for(let res of data){
                if(res.value !== 0){
                    responseData.push(res);
                }
            }
            setIndustryToType(responseData);
        }catch (error){
            console.log(error);
        }
    }

    const handleInvestorIndustryClick = async (type) => {
        try {
            const { data } = await axios.get(`${DEV_URL}/api/dashboard/investors/type?category=${encodeURIComponent(type)}`);
            const responseData = [];
            for(let res of data){
                if(res.value !== 0){
                    responseData.push(res);
                }
            }
            setIndustryToType(responseData);
        }catch (error){
            console.log(error);
        }
    }

    const handleTitleIndustryClick = async () => {
        try{
            if(userType === 'Company'){
                const { data } = await axios.get(`${DEV_URL}/api/dashboard/investors/type?category=All`);
                const responseData = [];
                for(let res of data){
                    if(res.value !== 0){
                        responseData.push(res);
                    }
                }
                console.log(data);
                setIndustryToType(responseData);
            }else if(userType === 'Investor'){
                const { data } = await axios.get(`${DEV_URL}/api/dashboard/companies/type?category=All`);
                const responseData = [];
                for(let res of data){
                    if(res.value !== 0){
                        responseData.push(res);
                    }
                }
                console.log(data);
                setIndustryToType(responseData);
            }
        }catch (error){
            console.log(error);
        }
    }

    const adminTypeHandler = (type) => {
        setAdminSelectType(type);
    }

    const chartDescHeader = () => {
        if(userType === 'Company'){
            return 'Investor Type Statistics';
        }else if(userType === 'Investor'){
            return 'Company Type Statistics';
        }else if(userType === 'Admin' && adminSelectType === 'Company'){
            return 'Investor Type Statistics';
        }else if(userType === 'Admin' && adminSelectType === 'Investor'){
            return 'Company Type Statistics';
        }
    }

    if(user && user.status === "Not Registered"){
        return(
            <div className="content-container">
                <NotRegisteredYet accountType={user.type}/>
            </div>
        )
    }

    if(user && user.status === "Registered"){
        return(
            <div className="content-container">
                <NotApprovedYet/>
            </div>
        )
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <>
            {/*<div className="dashboard-page">
            {<div className="nav-container">
                <CustomNavbar/>
            </div>
            <div className="content">
                <NotRegisteredYet/>
            </div>}
        </div>*/}
            <div className={'dashboard-screen'}>
                <div className={'dashboard-header'}>
                    <p className={'dashboard-title'}>DASHBOARD</p>
                </div>
                <div className={'dashboard-divider'}></div>
                <div className={'dashboard-container'}>
                    {userType === 'Admin' && <div className={'dashboard-type-select-container'}>
                        <div className={'dashboard-type-select'} onClick={() => adminTypeHandler('Company')}>
                            <DashboardTypeSelectButton title={'Companies'} selected={adminSelectType === 'Company'}/>
                        </div>
                        <div className={'dashboard-type-select'} onClick={() => adminTypeHandler('Investor')}>
                            <DashboardTypeSelectButton title={'Investors'} selected={adminSelectType === 'Investor'}/>
                        </div>
                        <div></div>
                    </div>}
                    <div className={`dashboard-summary-container ${userType === 'Company' && 'company-summary-container'} 
                    ${userType === 'Investor' && 'investor-summary-container'} ${userType === 'Admin' && 'admin-summary-container'}`}>
                        <div className={'summary-detail'}>
                            {userType !== 'Admin' && <p className={'summary-detail-text'} onClick={handleTitleIndustryClick}>
                                { userType === 'Company' && 'Investor Industry Preference' }
                                { userType === 'Investor' && 'Industry Distribution of Companies' }
                            </p>}
                            {userType === 'Admin' && <div className={'admin-total-statistics-container'}>
                                <p className={'admin-total-statistics'}>{adminSelectType === 'Company' ? totalNumberOfCompanies : totalNumberOfInvestors }</p>
                                <p className={'admin-total-statistics-description'}>Total {adminSelectType === 'Company' ? 'Companies' : 'Investors'} Registered</p>
                                <div></div>
                            </div>}    
                        </div>
                        <div className={'industry-list-container'}>
                            {displayType === 'Company' && companyIndustries.map((industry, index) => {
                                return(
                                    <div key={index} className={'industry-container'}>
                                        <p className={'industry-text industry-number'}>{companyindustryNumber[index]}</p>
                                        <p className={'industry-text industry-name'}>{industry}</p>
                                        <p className={'industry-text industry-link'}
                                           onClick={() => handleCompanyIndustryClick(industry)}>More Detail</p>
                                    </div>
                                )
                            })}
                            {displayType === 'Investor' && investorIndustries.map((industry, index) => {
                                return(
                                    <div key={index} className={'industry-container'}>
                                        <p className={'industry-text industry-number'}>{investorindustryNumber[index]}</p>
                                        <p className={'industry-text industry-name'}>{industry}</p>
                                        <p className={'industry-text industry-link'}
                                           onClick={() => handleInvestorIndustryClick(industry)}>More Detail</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={'dashboard-detail-container'}>
                        <p>Total Industry Statistics</p>
                        <div className={'dashboard-custom-chart-container'}>
                            <CustomBarChart data={industryToType}/>
                        </div>
                    </div>
                    {userType !== 'Admin' && <div>
                        <OverallMatches type={userType} matches={overallMatches}/>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default Dashboard;
