import React, { useState } from "react";
import { FaSearch, FaFilter, FaBan } from 'react-icons/fa';

import './SortingBar.css';

const industriesList = ['HealthTech', 'EduTech', 'AgriTech', 'FinTech', 'LogisticsTech (LogiTech)',
    'GreenTech', 'E-commerce/m-commerce', 'Life Sciences', 'IoT', 'Artificial Intelligence / Machine Learning',
    'Blockchain', 'Robotics', 'Big Data', 'Other'];
const investmentTypeList = ['Start-Up', 'Scale-Up', 'SME', 'R&D', 'Large-Enterprise'];

const SortingBar = ({ searchTerm, handleTerm, handleSearchClick, handleSortClick, cancelSortClick }) => {
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedInvestment, setSelectedInvestment] = useState('');

    const cancelSort = () => {
        setSelectedIndustry('');
        setSelectedInvestment('');
        cancelSortClick();
    }

    return(
        <div className={'sort-bar-container'}>
            <div className={'search-container'}>
                <input value={searchTerm} onChange={handleTerm} className={'form-control'} placeholder={'Enter Search word'}/>
                <div className="icon-container" onClick={handleSearchClick}>
                    <FaSearch className={'search-icon'}/>
                </div>
            </div>
            <div className={'sort-container'}>
                <div className="icon-container" onClick={() =>  handleSortClick(selectedIndustry, selectedInvestment)}>
                    <FaFilter className="filter-icon"/>
                </div>

                <select className="form-select" id="industry" value={selectedIndustry}
                        onChange={(e) => setSelectedIndustry(e.target.value)}>
                    <option value="" disabled hidden>Select Industry</option>
                    {industriesList.map((industry, index) => {
                        return <option value={industry} key={index}>{industry}</option>
                    })}
                </select>

                <select className="form-select" id="investment" value={selectedInvestment}
                        onChange={(e) => setSelectedInvestment(e.target.value)}>
                    <option value="" disabled hidden>Select Investment</option>
                    {investmentTypeList.map((investment, index) => {
                        return <option value={investment} key={index}>{investment}</option>
                    })}
                </select>

                <div className="icon-container" onClick={() =>  cancelSort()}>
                    <FaBan className="close-icon"/>
                </div>
            </div>
        </div>
    )
}

export default SortingBar;