import React, { useState } from "react";
import { FaInfoCircle } from 'react-icons/fa';

import './ScheduleTable.css';

const ScheduleTable = ({ events }) => {
    const [expandEvent, setExpandEvent] = useState('');
    const selectedEvents = events.filter((eve) => {
       return eve;
    });

    const dynamicClassNameToState = (status) => {
        if(status === 'Pending'){
            return 'meeting-pending';
        }else if(status === 'Accepted'){
            return 'meeting-accepted';
        }else if(status === 'Booked'){
            return 'meeting-booked';
        }else{
            return 'meeting-rejected';
        }
    }

    const eventClickHandler = (id) => {
        if(expandEvent === id){
            setExpandEvent('');
        }else {
            setExpandEvent(id);
        }
    }

    return(
        <div className={'events-table'}>
            <div className={'events-row events-header'}>
                <p className={'events-table-header'}>Date</p>
                <p className={'events-table-header'}>Time</p>
                <p className={'events-table-header'}>Mode</p>
                {/*<p className={'events-table-header'}>Location</p>*/}
                <p className={'events-table-header'}>Investor</p>
            </div>
            <div className={'events-detail'}>
                {selectedEvents.map((eve, index) => {
                        return(
                            <div key={index}>
                                <div className={`events-row ${dynamicClassNameToState(eve.status)}`}>
                                    <p className={'event-text'}>{ eve.day }</p>
                                    <p className={'event-text'}>{ eve.startingTime } - { eve.endingTime }</p>
                                    <p className={'event-text'}>{ eve.room }</p>
                                    {/*<p className={'event-text'}>{ eve.roomType }</p>*/}
                                    <div className={'event-action-row'}>
                                        <p className={'event-text'}>{ eve.investor }</p>
                                        <FaInfoCircle className={'event-expand-icon'} onClick={() => eventClickHandler(eve.id)}/>
                                    </div>
                                </div>
                                {expandEvent === eve.id && <div className={'expanded-event-container'}>
                                    <p className={'expanded-event-title'}>Note From Investor</p>
                                    <p className={'expanded-event-description'}>{ eve.noteToCompany }</p>
                                </div>}
                            </div>
                        )
                })}
            </div>
        </div>
    )

}

export default ScheduleTable;