import React from "react";
import { Image } from "antd";

import CustomButton from "../common/button/CustomButton";

import './RegisterAs.css';

const RegisterAs = ({ title, image, onClickItem }) => {
    return(
        <div className={'register-box'} onClick={onClickItem}>
            <img alt={'register-as-image'} src={image} className={'register-as-image'}/>
            <p className={'register-as-text'}>{title}</p>
            {/*<div className={'register-image-container'}>
                <Image src={image} width={60} className={'register-image'} preview={false}/>
            </div>
            <div className={'register-as-button-container'}>
                <CustomButton style={{fontFamily: 'Poppins'}} buttonClick={onClickItem} color={'#46a7d9'} title={ title }
                              isRegisterButton={true}/>
            </div>*/}
        </div>
    )
}

export default RegisterAs;