import React from 'react';
import { Button } from "antd";

import './InvestorSelectButton.css';

const InvestorSelectButton = ({ title, isApprove }) => {
    return(
        <div>
            <Button onClick={ () => {} }
                    className={ `${isApprove ? 'select-investor-button' : 'not-select-investor-button'}`}>{ title }</Button>
        </div>
    )
}

export default InvestorSelectButton;