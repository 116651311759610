import React, { useState } from "react";
import { Input, Select, Radio } from 'antd';

import './NotificationModel.css';

const { TextArea } = Input;
const { Option } = Select;

const NotificationModel = ({ subject, message, setMessageSubject, setMessageText, notificationSendTo, 
    setNotificationSendToValue, notificationSendUsers, notificationSelectedUser, setNotificationSelectOneUser }) => {
    
    const isShowSelectUser = () => {
        if(notificationSendTo === 2 || notificationSendTo === 3){
            return true
        }
        return false;
    }

    return (
        <div className="notification-model">
            <div className="message-input-container">
                <Radio.Group onChange={(e) => setNotificationSendToValue(e.target.value)}
                    value={notificationSendTo}>
                    <Radio value={1} className={'notification-model-radio'}>All</Radio>
                    <Radio value={2} className={'notification-model-radio'}>Companies</Radio>
                    <Radio value={3} className={'notification-model-radio'}>Investors</Radio>
                </Radio.Group>
            </div>
            {isShowSelectUser() && <div className="message-input-container">
                <Select onChange={setNotificationSelectOneUser} size={'large'} className="notification-select"
                    placeholder={'Select Which User To Send Notification'}>
                        { notificationSendUsers.map((user, index) => {
                            return <Option value={user} key={index}>{user}</Option>
                        })}
                </Select>
            </div>}
            <div className="message-input-container">
                <Input value={subject} onChange={(e) => setMessageSubject(e.target.value)} placeholder="Enter Subject"/>
            </div>
            <div className="message-input-container">
                <TextArea rows={4} value={message} onChange={(e) => setMessageText(e.target.value)} placeholder="Enter Message">
                </TextArea>
            </div>
        </div>
    )
}

export default NotificationModel;