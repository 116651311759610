import React, {useEffect, useState} from 'react';
import { Steps, Image, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dna } from "react-loader-spinner";
import axios from "axios";

import CompanyRegisterFormPart1 from "../../components/register/CompanyRegisterFormPart1";
import CompanyRegisterFormPart2 from "../../components/register/CompanyRegisterFormPart2";
import CompanyRegisterFormPart3 from "../../components/register/CompanyRegisterFormPart3";
import CompanyRegisterFormPart4 from "../../components/register/CompanyRegisterFormPart4";
import CustomButton from "../../components/common/button/CustomButton";
import CustomAlert from "../../components/common/alert/CustomAlert";
import { DEV_URL } from '../../configuration/config';
import { changeStateAsRegistered } from '../../store/actions/LoginAction';

import './RegisterAsScreen.css';
import logo from "../../assets/common/logo-new-1.png";

const { Step } = Steps;

const RegisterAsCompany = () => {
    const [searchParams] = useSearchParams();
    const user_id = searchParams.get('user_id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(0);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(state => state.auth.user);
    const [companyId, setCompanyId] = useState(0);

    const [formData, setFormData] = useState({
        userId: user.id,
        companyName: '',
        partnershipType: '',
        investmentType: [], //need to change as company stage and need to merge with bellow
        investmentTypeDescription: '',
        elevatorPitch: '',

        partnershipRequirement: '',
        isSustainable: 'Yes',
        numberOfEmployees: '',
        marketPresence: [],

        productMaturity: 'Developing',
        productDescription: '',
        productType: 'Proprietary',
        technologyEmployed: 'AI',//need to merge with bellow
        technologyEmployedDescription: '',

        collaterals: [],
        companyIndustry1: '',
        companyIndustry2: '',
        companyIndustry3: '',
        companyIndustry4: '',
        keyValueProposition: '',
        stakeholders: [],
        videoLink: ''
    });

    const [isFieldError, setIsFieldError] = useState({
        isCompanyNameError: false,
        isPartnershipTypeError: false,
        isInvestmentTypeError: false,
        isElevatorPitchError: false,

        isPartnershipRequirementError: false,
        isNumberOfEmployeesError: false,

        isProductDescriptionError: false,

        isCollateralsError: false,
        isCompanyIndustry1Error: false,
        isKeyValuePropositionError: false,
    });

    useEffect(() => {
        companyData()
    }, [user_id]);

    const companyData = async () => {
        if (user_id !== null) {
            try {
                const { data } = await axios.get(`${DEV_URL}/api/companies/user/${user_id}`);
                const companyId = data?.id;
                setCompanyId(companyId);

                const { data: companyData } = await axios.get(`${DEV_URL}/api/companies/${companyId}`);
                const companyInfo = companyData?.company;

                setFormData({
                    companyName: companyInfo?.companyName,
                    partnershipType: companyInfo?.companyType,
                    investmentType: companyInfo?.businessStages,
                    investmentTypeDescription: '',
                    elevatorPitch: companyInfo?.elevatorPitch,
                    partnershipRequirement: companyInfo?.partnershipRequirement,
                    isSustainable: companyInfo?.sustainability,
                    numberOfEmployees: parseInt(companyInfo?.numberOfEmployees),
                    marketPresence: companyInfo?.marketPresences,
                    productMaturity: companyInfo?.productMaturity,
                    productDescription: companyInfo?.companyProducts,
                    productType: companyInfo?.productOwnership,
                    technologyEmployed: companyInfo?.coreTechnology,
                    technologyEmployedDescription: '',
                    collaterals: [],
                    companyIndustry1: companyInfo?.companyIndustry1,
                    companyIndustry2: companyInfo?.companyIndustry2,
                    companyIndustry3: companyInfo?.companyIndustry3,
                    companyIndustry4: companyInfo?.companyIndustry4,
                    keyValueProposition: companyInfo?.keyValueProposition,
                    stakeholders: companyInfo?.stakeholders,
                    videoLink: companyInfo?.videoLink
                });

            } catch (error) {
                console.log(error);
            }
        }
    }

    const steps = [
        {
            title: 'General Info',
            component: CompanyRegisterFormPart1,
        },
        {
            title: 'Special Info',
            component: CompanyRegisterFormPart2,
        },
        {
            title: 'Company Info 1',
            component: CompanyRegisterFormPart3,
        },
        {
            title: 'Company Info 2',
            component: CompanyRegisterFormPart4,
        }
    ];

    let checkFinal = currentStep < steps.length - 1;


    const handleNext = () => {
        setIsError(false);
        if(currentStep === 0){
            const companyNameValid = formData.companyName.length > 1;
            const partnershipTypeValid = formData.partnershipType.length > 0;
            const elevatorPitchValid = formData.elevatorPitch.length > 5;
            const investmentTypeValid = formData.investmentType.length > 0;

            setIsFieldError({
                isCompanyNameError: !companyNameValid,
                isPartnershipTypeError: !partnershipTypeValid,
                isInvestmentTypeError: !investmentTypeValid,
                isElevatorPitchError: !elevatorPitchValid,

                isPartnershipRequirementError: false, isNumberOfEmployeesError: false,

                isProductDescriptionError: false,

                isCollateralsError: false, isCompanyIndustry1Error: false, isKeyValuePropositionError: false
            });

            if(partnershipTypeValid && elevatorPitchValid && investmentTypeValid && companyNameValid){
                setCurrentStep(currentStep + 1);
            }else{
                setIsError(true);
            }
        }else if(currentStep === 1){
            const partnershipRequirementValid = formData.partnershipRequirement.length > 0;
            const numberOfEmployeesValid = Number.isInteger(Number(formData.numberOfEmployees)) && formData.numberOfEmployees.toString().length > 0;

            setIsFieldError({
                isCompanyNameError: false, isPartnershipTypeError: false, isInvestmentTypeError: false, isElevatorPitchError: false,

                isPartnershipRequirementError: !partnershipRequirementValid,
                isNumberOfEmployeesError: !numberOfEmployeesValid,

                isProductDescriptionError: false,

                isCollateralsError: false, isCompanyIndustry1Error: false, isKeyValuePropositionError: false
            });

            if(partnershipRequirementValid && numberOfEmployeesValid){
                setCurrentStep(currentStep + 1);
            }else{
                setIsError(true);
            }
        }else if(currentStep === 2){
            const productDescriptionValid = formData.productDescription.length > 5;

            setIsFieldError({
                isCompanyNameError: false, isPartnershipTypeError: false, isInvestmentTypeError: false, isElevatorPitchError: false,

                isPartnershipRequirementError: false, isNumberOfEmployeesError: false,

                isProductDescriptionError: !productDescriptionValid,

                isCollateralsError: false, isCompanyIndustry1Error: false, isKeyValuePropositionError: false
            });

            if(productDescriptionValid){
                setCurrentStep(currentStep + 1);
            }else {
                setIsError(true)
            }
        }
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    }

    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    async function handleFormSubmit() {
        const generatedCollateralFileNames = [];

        try{
            for(let i = 0; i < formData.collaterals.length; i++){
                const fileExtention = formData.collaterals[i].name.split('.').pop();

                const { data } = await axios.post(`${DEV_URL}/api/file`,{
                    fileName: formData.collaterals[i].name,
                    fileExtension: fileExtention
                });

                const generatedUrl = data.url;
                const generatedFileName = data.name;

                generatedCollateralFileNames.push(generatedFileName);

                await axios.put(generatedUrl, formData.collaterals[i], {
                    headers: {
                        "Content-Type": fileExtention || "application/octet-stream",
                    },
                });
            }
            formData.collaterals = generatedCollateralFileNames;
        }catch(error){
            console.log(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsError(false);

        const companyIndustry1Valid = formData.companyIndustry1.trim().length > 0;
        const keyValuePropositionValid = formData.keyValueProposition.trim().length > 5;

            setIsFieldError({
                isCompanyNameError: false, isPartnershipTypeError: false,
                isInvestmentTypeError: false, isElevatorPitchError: false,

                isPartnershipRequirementError: false, isNumberOfEmployeesError: false,

                isProductDescriptionError: false,

                isCollateralsError: true,
                isCompanyIndustry1Error: !companyIndustry1Valid,
                isKeyValuePropositionError: !keyValuePropositionValid
            });

            if(companyIndustry1Valid && keyValuePropositionValid){

                const finalCompanyStageList = [...formData.investmentType];
                if(finalCompanyStageList.includes(formData.investmentTypeDescription)){
                    finalCompanyStageList.push(formData.investmentTypeDescription);
                }
                const finalCoreTechnology = formData.technologyEmployed !== 'Other' ? formData.technologyEmployed :
                    formData.technologyEmployedDescription;

                await handleFormSubmit();

                if(user_id === null){
                    try {
                        const response =  await axios.post(`${DEV_URL}/api/register/company`, {
                            userId: formData.userId,
                            companyType: formData.partnershipType,
                            partnershipRequirement: formData.partnershipRequirement,
                            productMaturity: formData.productMaturity,
                            productOwnership: formData.productType,
                            coreTechnology: finalCoreTechnology,
                            companyIndustry1: formData.companyIndustry1,
                            companyIndustry2: formData.companyIndustry2,
                            companyIndustry3: formData.companyIndustry3,
                            companyIndustry4: formData.companyIndustry4,
                            companyName: formData.companyName,
                            elevatorPitch: formData.elevatorPitch,
                            sustainability: formData.isSustainable,
                            numberOfEmployees: parseInt(formData.numberOfEmployees),
                            companyProducts: formData.productDescription,
                            keyValueProposition: formData.keyValueProposition,
                            companyStage: finalCompanyStageList,
                            stakeholders: formData.stakeholders,
                            collaterals: formData.collaterals,
                            marketPresence: formData.marketPresence,
                            videoLink: formData.videoLink
                        });
                        if (response.status===201) {
                            console.log('success');
                        }
                        dispatch(changeStateAsRegistered());
                        message.success('Successfully submitted !');
                        navigate('/');
                    } catch (err) {
                        console.error(err);
                    }
                }else {
                    try{
                        const response = await axios.post(`${DEV_URL}/api/register/company/update`,{
                            companyId: companyId,
                            companyType: formData.partnershipType,
                            partnershipRequirement: formData.partnershipRequirement,
                            productMaturity: formData.productMaturity,
                            productOwnership: formData.productType,
                            coreTechnology: finalCoreTechnology,
                            companyIndustry1: formData.companyIndustry1,
                            companyIndustry2: formData.companyIndustry2,
                            companyIndustry3: formData.companyIndustry3,
                            companyIndustry4: formData.companyIndustry4,
                            companyName: formData.companyName,
                            elevatorPitch: formData.elevatorPitch,
                            sustainability: formData.isSustainable,
                            numberOfEmployees: parseInt(formData.numberOfEmployees),
                            companyProducts: formData.productDescription,
                            keyValueProposition: formData.keyValueProposition,
                            companyStage: finalCompanyStageList,
                            stakeholders: formData.stakeholders,
                            collaterals: formData.collaterals,
                            marketPresence: formData.marketPresence,
                            videoLink: formData.videoLink
                        });

                        message.success('Successfully submitted !');
                        navigate('/profile');
                    }catch (error){
                        console.error(error);
                    }
                }

            }else {
                setIsError(true);
            }
        setIsLoading(false);
    };

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    const CurrentStepComponent = steps[currentStep].component;

    return (
        <div className={'register-as-page'}>
            <div className={'register-image-container'}>
                <Image src={ logo } preview={false} width={160} height={180} className={'register-image'}/>
            </div>
            <div className={'register-info-container'}>
                <div className={'register-page-title-container'}>
                    <p className={'register-page-title'}>Registration</p>
                </div>
                <div className={'register-page-description-container'}>
                    <p className={'register-page-description-text'}>Please Fill In the Necessary Information in the Relevant Fields</p>
                </div>
                {isError && <CustomAlert description={'form input error please recheck and enter inputs'}/>}
                <div className={'register-field-box'}>
                    <CurrentStepComponent onChange={handleChange} formData={formData} isFieldError={isFieldError}/>
                </div>
                <div className={'register-form-footer'}>
                    <div className={'register-step-container'}>
                        <div className={'register-step'}>
                            <Steps progressDot current={currentStep} className="custom-steps">
                                {steps.map((step, index) => (
                                    <Step key={index} title={step.title} />
                                ))}
                            </Steps>
                        </div>
                    </div>
                    <div className={'register-step-button'}>
                        <div className={'register-button-container'}>
                            { currentStep !== 0 && <CustomButton title={'Previous'} className={'register-button'} buttonClick={ handlePrevious } color={'#6495ED'}/>  }
                        </div>
                        { checkFinal ? (
                            <CustomButton title={'Next'} buttonClick={ handleNext } className={'register-button'} color={'#6495ED'}/>
                        ) : (
                            <CustomButton title={'Submit'} buttonClick={handleSubmit} className={'register-button'} color={'#6495ED'}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterAsCompany;
