import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import './CustomTopBar.css';

import logo from '../../../assets/common/logo-new-1.png';
import menu from '../../../assets/dashboard/new-icon/menu-2.png';

const CustomTopBar = ({ sideBarToggle }) => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState('');
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if(user.type === 3){
            setUserType('Admin');
        }else if(user.type === 2){
            setUserType('Investor');
        }else if(user.type === 1){
            setUserType('Company');
        }
    }, [user]);

    const logoutHandler = () => {
        localStorage.removeItem('user');
        navigate('/log-in');
    }

    const sidebarToggleHandler = () => {
        sideBarToggle();
    }

    return(
        <div className={'custom-top-bar'}>
            <div className={'custom-top-bar-left-container'}>
                <img src={menu} className={'hamburger-menu-icon'} alt={'hamburger-menu'} onClick={sidebarToggleHandler}/>
                <div className={'custom-top-bar-left-box'}>
                    <img src={logo} alt={'logo-image-top-logo'} className={'custom-top-bar-logo'}/>
                    <div>
                        <p className={'custom-top-bar-title'}>Digital</p>
                        <p className={'custom-top-bar-title'}>Investment</p>
                        <p className={'custom-top-bar-title'}>Summit 2030</p>
                    </div>
                </div>
                <p className={'custom-top-bar-type'}>{userType}</p>
            </div>
            <div className={'custom-top-bar-no-content'}></div>
            <div className={'custom-top-bar-logout-container'}>
                <p className={'custom-top-bar-logout'} onClick={logoutHandler}>Sign out</p>
            </div>
        </div>
    )
}

export default CustomTopBar;