import React from "react";
import { Button } from "antd";

import './ProfileSelectButton.css';

const ProfileSelectButton = ({ title, clickHandler }) => {
    return(
        <div>
            <Button onClick={ clickHandler }
                                className={'select-profile-button'}>
                { title }</Button>
        </div>
    )
}

export default ProfileSelectButton;