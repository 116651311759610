import React, { useState, useEffect } from 'react';
import { Select, InputNumber, message, Tooltip } from 'antd';

import CustomTextInputInline from "../common/textInput/CustomTextInputInline";
import SelectOptionButton from "../common/button/SelectOptionButton";

import './CompanyRegisterForm.css';
import {PlusCircleOutlined} from "@ant-design/icons";

const { Option } = Select;

const partnershipRequirements = ['Investment', 'Partnership', 'Collaboration'];
//const marketList = ['North America', 'South America', 'Europe', 'Asia', 'ANZ', 'Middle East', 'Africa'];

const CompanyRegisterFormPart2 = ({ onChange, formData, isFieldError }) => {
    const [marketPresence, setMarketPresence] = useState([{ region: '', percentage: 0 }]);
    const [marketList, setMarketList] = useState(['North America', 'South America', 'Europe', 'Asia', 'ANZ', 'Middle East',
        'Africa']);

    useEffect(() => {
        if(formData.marketPresence.length > 0){
            setMarketPresence(formData.marketPresence);
        }
    },[]);

    const handlePartnershipRequirement = (type) => {
        onChange('partnershipRequirement', type)
    }

    const handleIsSustainable = (type) => {
        onChange('isSustainable', type);
    }

    const handleNumberOfEmployees = (e) => {
        const { value } = e.target;
        onChange('numberOfEmployees', value);
    }

    const handleMarketPresenceType = (index, type) => {
        const updateMarketPresence = [...marketPresence];
        updateMarketPresence[index].region = type;
        setMarketPresence(updateMarketPresence);

        let updatedMarketList = marketList.filter(market => market !== type);
        setMarketList(updatedMarketList);
    }

    const handleMarketPresenceNumber = (index, value) => {
        const updateMarketPresence = [...marketPresence];
        updateMarketPresence[index].percentage = value;
        setMarketPresence(updateMarketPresence);
    }

    const addMarketPresence = () => {
        const updateMarketPresence = [...marketPresence];
        updateMarketPresence.push({ region: '', percentage: 0 });
        setMarketPresence(updateMarketPresence);
    }

    const addMarketPresenceToState = () => {
        const { isValid } = checkPercentageValid();
        if(isValid){
            onChange('marketPresence', marketPresence);
        }else {
            const updateMarketPresence = [...marketPresence];
            updateMarketPresence[updateMarketPresence.length - 1].percentage = 0;
            setMarketPresence(updateMarketPresence);

            message.warning('Enter valid percentage values');
        }
    }

    const checkPercentageValid = () => {
        const sumOfPercentages = marketPresence.reduce((acc, item) => acc +
            parseInt(item.percentage.toString()), 0);
        const isValid = sumOfPercentages <= 100;
        return {
            sumOfPercentages,
            isValid
        };
    }

    return (
        <div className={'register-form'}>
            <div className={'register-select-container'}>
                <label htmlFor={'partnershipRequirement'} className="cus-label">
                    Partnership Requirement
                </label>
                <div className={'register-select-input-container'}>
                    <Tooltip title="Partnership Requirement" color='#264294' placement="bottom">
                        <Select className={'register-select'} value={formData.partnershipRequirement}
                        onChange={handlePartnershipRequirement} size={'large'}
                                placeholder={'Partnership Requirement'}>
                            { partnershipRequirements.map((partnershipReq, index) => {
                                return <Option value={partnershipReq} key={index}>{ partnershipReq }</Option>
                            })}
                        </Select>
                    </Tooltip>    
                    { isFieldError.isPartnershipRequirementError && <p className="error-message">Select Partnership Requirement</p> }
                </div>
            </div>

            <div className={'register-button-group-container2-custom'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'isSustainable'} className="cus-label" style={{ textAlign: 'left' }}>
                        Does Your Company address Sustainability
                    </label>
                </div>
                <div className={'register-button-group2-extra'}>
                    <SelectOptionButton title={'Yes'} selected={formData.isSustainable === 'Yes'}
                                        clickHandler={() => handleIsSustainable('Yes')} />
                    <SelectOptionButton title={'No'} selected={formData.isSustainable === 'No'}
                                        clickHandler={() => handleIsSustainable('No')} />
                </div>
            </div>

            <CustomTextInputInline inputValue={formData.numberOfEmployees} placeholder={'Enter Number of Employees'} isNormal={true}
                              setValue={handleNumberOfEmployees} title={'Number of Employees'}
                              id={'numberOfEmployees'} htmlFor={'numberOfEmployees'} large={true}
                                   isErrorMessage={isFieldError.isNumberOfEmployeesError}
                                   errorMessage={'Enter valid Number of Employees'}/>

            <div className={'register-market-container'}>
                <label htmlFor={'marketPresence'} className="cus-label market-title-text">
                    Export Market Share | Percentage of Presence
                </label>
                <div style={{ marginTop: '10px' }}>
                    {marketPresence.map((market, index) => {
                        return(
                            <div key={index} className={'market-container'}>
                                <p className={'market-presence-text'}>{index + 1}.</p>
                                <Tooltip title="Region" color='#264294' placement="bottom">
                                    <Select className={'market-selector'} size={'large'} value={market.region}
                                            onChange={(type) => handleMarketPresenceType(index, type)}
                                            placeholder={'Select Regions'} onBlur={addMarketPresenceToState}>
                                        { marketList.map((mark, index) => {
                                            return <Option value={mark} key={index}>{ mark }</Option>
                                        })}
                                    </Select>
                                </Tooltip>
                                <InputNumber value={market.percentage} placeholder={'Enter percentage'} min={0} max={100}
                                             onBlur={addMarketPresenceToState}
                                             onChange={(value) => handleMarketPresenceNumber(index, value)}/>
                            </div>
                        )
                    })}
                </div>
                <div className={'market-addButton'} onClick={addMarketPresence}>
                    <PlusCircleOutlined style={{ fontSize: '28px', color: 'white' }}/>
                </div>
            </div>

        </div>
    );
};

export default CompanyRegisterFormPart2;
