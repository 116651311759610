import React from "react";

import CustomTextInputInline from "../common/textInput/CustomTextInputInline";
import SelectOptionButton from "../common/button/SelectOptionButton";

import './CompanyRegisterForm.css';

/*const countries = ['USA', 'UK', 'Japan', 'China', 'Russia'];
const investorTypes = ['Angel-Investor', 'Business-Incubator', 'Credit-Enhancement', 'Venture-Capitalists', 'Other'];
const investmentType = ['Start-Up', 'Scale-Up', 'Product', 'R&D'];
const investmentIndustryPreferenceList = ['Banking & Finance', 'Insurance', 'Digital Marketing', 'Entertainment & Media', 'Gaming',
    'Retail & Distribution', 'Tourism & Hospitality', 'Cultural & Creative Industries', 'Professional Service (Legal)',
    'Healthcare', 'Education & Training', 'ESG', 'Manufacturing', 'Energy & Utilities', 'Agriculture', 'ICT & Telecommunications',
    'Engineering & Construction', 'Transportation, Supply Chain & Logistics', 'Public Sector & Government',
    'Research, Development and innovation'
];*/

const InvestorRegisterFormPart2 = ({ onChange, formData, isFieldError }) => {

    /*const handleNumberOfEmployeeChange = (e) => {
        const { value } = e.target;
        const numaricalValue = value.replace(/[^0-9]/g, '');
        onChange('numberOfEmployees', numaricalValue);
    }

    const handleAssetsUnderManagementChange = (e) => {
        const { value } = e.target;
        const numaricalValue = value.replace(/[^0-9]/g, '');
        onChange('assetsUnderManagement', numaricalValue);
    }*/

    const handleInvestmentAmountChange = (e) => {
        const { value } = e.target;
        const numaricalValue = value.replace(/[^0-9]/g, '');
        onChange('investmentAmount', numaricalValue);
    }

    const handleInvestorTypeChange = (type) => {
        onChange('investorType', type);
    }

    const handleInvestorTypeDescription = (e) => {
        onChange('investorTypeDescription', e.target.value);
    }

    return(
        <div className={'register-form'}>
            {/*<CustomTextInputInline  large={true} title={'Total Employees'} isNormal={true}
                                    placeholder={'Enter Number of Employees'} inputValue={formData.numberOfEmployees}
                                    setValue={handleNumberOfEmployeeChange} id={'numberOfEmployees'} htmlFor={'numberOfEmployees'}/>
            <CustomTextInputInline  large={true} title={'Values of Assets (USD)'} isNormal={true}
                                    placeholder={'Enter Assets under Management'} inputValue={formData.assetsUnderManagement}
                                    setValue={handleAssetsUnderManagementChange} id={'assetsUnderManagement'}
                                    htmlFor={'assetsUnderManagement'}/>*/}
            <div className={'register-button-group-container'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'investorType'} className="cus-label">
                        Investor Type
                    </label>
                </div>
                <div className={'register-button-group'}>
                    <SelectOptionButton title={'Angel-Investor'} selected={formData.investorType === 'Angel-Investor'}
                                        clickHandler={() => handleInvestorTypeChange('Angel-Investor')}/>
                    <SelectOptionButton title={'Business-Incubator'} selected={formData.investorType === 'Business-Incubator'}
                                        clickHandler={() => handleInvestorTypeChange('Business-Incubator')}/>
                    <SelectOptionButton title={'Credit-Enhancement'} selected={formData.investorType === 'Credit-Enhancement'}
                                        clickHandler={() => handleInvestorTypeChange('Credit-Enhancement')}/>
                    <SelectOptionButton title={'Venture-Capitalists'} selected={formData.investorType === 'Venture-Capitalists'}
                                        clickHandler={() => handleInvestorTypeChange('Venture-Capitalists')}/>
                    <SelectOptionButton title={'Other'} selected={formData.investorType === 'Other'}
                                        clickHandler={() => handleInvestorTypeChange('Other')}/>
                </div>
            </div>
            <CustomTextInputInline  large={true} isOther={true} title={'investorTypeDescription'}
                                    isNormal={true} placeholder={'Please mention if other'}
                                    inputValue={formData.investorTypeDescription} setValue={handleInvestorTypeDescription}
                                    id={'investorTypeDescription'} htmlFor={'investorTypeDescription'}
                                    isDisable={formData.investorType !== 'Other'}/>
            <CustomTextInputInline  large={true} title={'Investment Amount'} isNormal={true} placeholder={'Enter Investment Amount'}
                                    inputValue={formData.investmentAmount} setValue={handleInvestmentAmountChange}
                                    id={'investmentAmount'} htmlFor={'investmentAmount'}/>
        </div>
    )
}

export default InvestorRegisterFormPart2;