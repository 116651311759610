import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './DisclaimerScreen.css';

import logo from '../../assets/common/logo-new-1.png';

const DisclaimerScreen = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const type = searchParams.get('type');

    const goBack = () => {
        if(type === 'company'){
            navigate('/sign-up-as?type=company');
        }else if(type === 'investor'){
            navigate('/sign-up-as?type=investor');
        }
    }

    return(
        <div className={'disclaimer-screen'}>
            <div className={'disclaimer-container'}>
                <div className={'disclaimer-header-container'}>
                    <div className={'disclaimer-image-container'}>
                        <img src={logo} className={'disclaimer-logo'} alt={'logo'}/>
                    </div>
                    <div>
                        <p className={'disclaimer-title'}>DISCLAIMER</p>
                    </div>
                </div>
                <div className={'disclaimer-text-container'}>
                    <p className={'disclaimer-text'}>This privacy statement describes how sldigitalinvest.lk collects,
                        uses, discloses and safeguards your
                        information when you visit our website, www.sldigitalinvest.lk. We take due consideration of the privacy of your
                        data, in compliance with the applicable provisions of Data Protection Laws. By using this Site, you are subject
                        to the terms and conditions of this Privacy Statement. </p>
                    <p className={'disclaimer-text-bold'}>I.Scope of application </p>
                    <p className={'disclaimer-text'}>The below given data protection notice is pertaining to the investment purpose
                        orient internet presence.</p>
                    <p className={'disclaimer-text-bold'}>II.Use of your data </p>
                    <p className={'disclaimer-text'}>We have articulated below how your personal data will be processed when you visit our website
                        and also to apprise you of your rights as stipulated in the Data Protection Laws. </p>
                    <p className={'disclaimer-text-bold'}>B.Categories of data will we use, and for what purposes do we process personal data </p>
                    <p className={'disclaimer-text'}>In general, you are free to visit our website anonymously, as sldigitalinvest.lk website  does not
                        save any personal or traceable data (e.g. IP addresses) of visitors to its website. We automatically receive certain data
                        about the computer or device you are using when you visit this Site, including: your Internet Protocol (IP) address;
                        information about the browser or type of device you are using; data collected through cookies, pixel tags, and other similar
                        technologies; the date and time of your visit; and the web page you visited immediately prior to visiting this Site etc.
                        We also collect data about videos or other content you view on this Site, like the type and name of the content and the
                        time viewed. </p>
                    <p className={'disclaimer-text-bold'}>C.Legal framework</p>
                    <p className={'disclaimer-text'}>We process your data on the basis of the provisions of the Electronic Transactions
                        Act No. 19 of 2006, Payment Devices Frauds Act No 30 of 2006, the Intellectual Property Rights Acts, and
                        Computer Crimes Act No 24 of 2007 and all other laws applicable to the processing of personal data.</p>
                    <p className={'disclaimer-text-bold'}>D.Data use</p>
                    <p className={'disclaimer-text'}>sldigitalinvest.lk collects and process personal data of its customers and
                        potential customers to underwrite risks, assess claims and provide the most desired insurance solution/services.
                        Personal data can also be processed pertaining to other aspects as well including carrying out tasks required by
                        law or regulations like actuarial or statistics operations; product and service development; promotion of data
                        security, marketing and other necessary customer relationship management tasks, and the prevention of abuse etc.
                        Personal data will be only used for predefined purposes and related matters, commensurate with these
                        requirements and the personal data will be processed while ensuring that a legally valid reason exists.</p>
                    <p className={'disclaimer-text'}>sldigitalinvest.lk may disclose personal data for the purposes
                        enunciated below, to the extent allowed by the Data Protection Act:</p>
                    <p className={'disclaimer-text'}>a)To the group of companies</p>
                    <p className={'disclaimer-text'}>b)To service partners and third-party service providers</p>
                    <p className={'disclaimer-text'}>c)To business partners in the context of a corporate
                        transaction (e.g. mergers and acquisitions etc.).</p>
                    <p className={'disclaimer-text'}>d)To regulatory and law enforcement agencies.</p>
                    <p className={'disclaimer-text'}>e)Third parties provide storage facilities for data subject
                        to having proper Non-Disclosure Agreements</p>
                    <p className={'disclaimer-text'}>f) Solicitors and other professional services firms
                        (including our auditors, actuaries and reinsurers etc.)</p>
                    <p className={'disclaimer-text'}>We may also use data to administer and manage the company website;
                        customize your user experience; contact you about sldigitalinvest.lk’s products and services;
                        share promotional materials and offers (collectively known as “marketing communications”);
                        Carry out our internal business purposes, such as corporate transactions, audits, and data analysis;
                        conduct research and analytics about your use of this Site and interaction with us; develop new products;
                        improve this Site and our existing products and services; identify usage trends; assess the performance of
                        our advertisements; enhance our advertising campaigns; and determine the efficiency of our communications.</p>
                    <p className={'disclaimer-text-bold'}>III.Cookies and other internet technologies</p>
                    <p className={'disclaimer-text'}>This Site uses cookies and similar technologies including cookies
                        and technologies from third-party providers, to enable certain features and functionality and to collect
                        extra information that facilitates us to improve this Site and our services. This Site may use session
                        cookies (which expire when you close your browser) and persistent cookies (which remain on your device
                        after you close your browser until you delete them or they expire). If you do not wish data to be collected
                        from your device via cookies, you may decline the use of cookies.</p>
                    <p className={'disclaimer-text-bold'}>IV.Data Security</p>
                    <p className={'disclaimer-text'}>We use reasonable measures to mitigate any such risk though no
                        data transmission over the internet or data storage can be assured to be 100% secured. </p>
                </div>
            </div>
        </div>
    )
}

export default DisclaimerScreen;