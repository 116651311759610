import React from 'react';
import { Select, Input, Tooltip } from "antd";

import SelectOptionButton from "../common/button/SelectOptionButton";
import CustomTextInputInline from "../common/textInput/CustomTextInputInline";

const { Option } = Select;
const { TextArea } = Input;

const partnershipTypeList = ["Limited-Liability", "Sole-Proprietary", "Partnership", "Guarantee-Company"];

const CompanyRegisterFormPart1 = ({ onChange, formData, isFieldError }) => {

    const handlePartnershipType = (type) => {
        onChange('partnershipType', type);
    }

    const handleInvestmentTypeChange = (type) => {
        const checkExisted = formData.investmentType.findIndex(inv => inv === type);
        if(checkExisted === -1){
            const investmentList = [...formData.investmentType, type];
            onChange('investmentType', investmentList);
        }else {
            const currentInvestementsTypes = formData.investmentType.filter(inv => inv !== type);
            onChange('investmentType', currentInvestementsTypes);
        }
        //onChange('investmentType', type);
    }

    const checkInvestorStypeSelected = (type) => {
        const check = formData.investmentType.some(inv => inv === type);
        return check;
    }

    const handleInvestmentTypeDescription = (e) => {
        onChange('investmentTypeDescription', e.target.value);
    }

    const handleElevatorPitch = (e) => {
        onChange('elevatorPitch', e.target.value);
    }

    const handleCompanyName = (e) => {
        onChange('companyName', e.target.value);
    }

    const handleVideoLink = (e) => {
        const { value } = e.target;
        onChange('videoLink', value);
    }

    return (
        <div className={'register-form'}>
            <CustomTextInputInline inputValue={formData.companyName} placeholder={'Enter Company Name'} isNormal={true}
                                   setValue={handleCompanyName} title={'Company Name'} id={'companyName'} htmlFor={'companyName'}
                                   isCustomSize={true} isErrorMessage={isFieldError.isCompanyNameError} errorMessage={'Enter valid Company Name'}/>
            <div className={'register-select-container'}>
                <label htmlFor={'partnershipType'} className="cus-label">
                    Company Type
                </label>
                <div className={'register-select-input-container2'}>
                    <Tooltip title="Company Type" color='#264294' placement="bottom">
                        <Select className={'register-select'} value={formData.partnershipType} onChange={handlePartnershipType} size={'large'}
                                placeholder={'Company Type'}>
                            { partnershipTypeList.map((country, index) => {
                                return <Option value={country} key={index}>{country}</Option>
                            })}
                        </Select>
                    </Tooltip>
                    { isFieldError.isPartnershipTypeError && <p className="error-message">Select Partnership Type</p> }
                </div>
            </div>

            <div className={'register-button-group-container2'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'investmentType'} className="cus-label" style={{ textAlign: 'left' }}>
                        Company Stage
                    </label>
                </div>
                <div>
                    <div className={'register-button-group2'}>
                        <SelectOptionButton title={'Start-Up'} selected={checkInvestorStypeSelected('Start-Up')}
                                            clickHandler={() => handleInvestmentTypeChange('Start-Up')} isSmall={true}/>
                        <SelectOptionButton title={'Scale-Up'} selected={checkInvestorStypeSelected('Scale-Up')}
                                            clickHandler={() => handleInvestmentTypeChange('Scale-Up')} isSmall={true}/>
                        <SelectOptionButton title={'R&D'} selected={checkInvestorStypeSelected('R&D')}
                                            clickHandler={() => handleInvestmentTypeChange('R&D')} isSmall={true}/>
                        <SelectOptionButton title={'SME'} selected={checkInvestorStypeSelected('SME')}
                                            clickHandler={() => handleInvestmentTypeChange('SME')} isSmall={true}/>
                        <SelectOptionButton title={'Large-Enterprise'} selected={checkInvestorStypeSelected('Large-Enterprise')}
                                            clickHandler={() => handleInvestmentTypeChange('Large-Enterprise')} isSmall={true}/>
                        <SelectOptionButton title={'Other'} selected={checkInvestorStypeSelected('Other')}
                                            clickHandler={() => handleInvestmentTypeChange('Other')} isSmall={true}/>
                    </div>
                    { isFieldError.isInvestmentTypeError && <p className="error-message special-error">Select Investment Types</p> }
                </div>
            </div>

            <div className={'register-button-and-field-container'}>
                <div></div>
                <CustomTextInputInline large={true} isOther={true} title={'investmentTypeDescription'}
                                        isNormal={true} placeholder={'Please mention if other'}
                                        inputValue={formData.investmentTypeDescription}
                                        setValue={handleInvestmentTypeDescription}
                                        id={'investmentTypeDescription'}
                                        htmlFor={'investmentTypeDescription'} isDisable={!formData.investmentType.some(inv => inv === 'Other')}/>
            </div>

            <div className={'register-text-area-container'}>
                <label htmlFor={'elevatorPitch'} className="cus-label">
                    Elevator Pitch
                </label>
                <div className={'register-text-area-input-container2'}>
                    <TextArea rows={4} value={formData.elevatorPitch}
                              className={'register-text-area'}
                              placeholder={'Enter Elevator Pitch'}
                              onChange={handleElevatorPitch} />
                    { isFieldError.isElevatorPitchError && <p className="error-message">Enter valid Elevator Pitch</p> }
                </div>
            </div>
            <CustomTextInputInline inputValue={formData.videoLink} placeholder={'Enter Elevator Pitch Video Link'} isNormal={true}
                                   setValue={handleVideoLink} title={'Elevator Pitch Video Link'} id={'videoLink'} htmlFor={'videoLink'}
                                   isCustomSize={true}/>

        </div>
    );
};

export default CompanyRegisterFormPart1;
