import React from "react";

import './CompanyRegisterForm.css';
import SelectOptionButton from "../common/button/SelectOptionButton";
import CustomTextInputInline from "../common/textInput/CustomTextInputInline";
import { Select, Tooltip } from "antd";

const { Option } = Select;

const investmentIndustryPreferenceList = ['HealthTech', 'EduTech', 'AgriTech', 'FinTech', 'LogisticsTech (LogiTech)',
    'GreenTech', 'E-commerce/m-commerce', 'Life Sciences', 'IoT', 'Artificial Intelligence / Machine Learning',
    'Blockchain', 'Robotics', 'Big Data', 'Other'
];

const InvestorRegisterFormPart3 = ({ onChange, formData, isFieldError }) => {

    const handleInvestorTypeChange = (type) => {
        const checkExisted = formData.investmentType.findIndex(inv => inv === type);
        if(checkExisted === -1){
            const investmentList = [...formData.investmentType, type];
            onChange('investmentType', investmentList);
        }else {
            const currentInvestementsTypes = formData.investmentType.filter(inv => inv !== type);
            onChange('investmentType', currentInvestementsTypes);
        }
    }

    const checkInvestorStypeSelected = (type) => {
        const check = formData.investmentType.some(inv => inv === type);
        return check;
    }

    const handleInvestmentTypeDescription = (e) => {
        onChange('investmentTypeDescription', e.target.value);
    }

    const handleInvestmentIndustry1 = (type) => {
        onChange('investmentIndustryPreference1', type);
    }

    const handleInvestmentIndustry2 = (type) => {
        onChange('investmentIndustryPreference2', type);
    }

    const handleInvestmentIndustry3 = (type) => {
        onChange('investmentIndustryPreference3', type);
    }

    const handleInvestmentIndustry4 = (type) => {
        onChange('investmentIndustryPreference4', type);
    }

    return(
        <div className={'register-form'}>
            <div className={'register-button-group-container2'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'investmentType'} className="cus-label" style={{ textAlign: 'left' }}>
                        Investment Type
                    </label>
                </div>
                <div >
                    <div className={'register-button-group2'}>
                        <SelectOptionButton title={'Start-Up'} selected={checkInvestorStypeSelected('Start-Up')}
                                            clickHandler={() => handleInvestorTypeChange('Start-Up')} isSmall={true}/>
                        <SelectOptionButton title={'Scale-Up'} selected={checkInvestorStypeSelected('Scale-Up')}
                                            clickHandler={() => handleInvestorTypeChange('Scale-Up')} isSmall={true}/>
                        <SelectOptionButton title={'SME'} selected={checkInvestorStypeSelected('SME')}
                                            clickHandler={() => handleInvestorTypeChange('SME')} isSmall={true}/>
                        <SelectOptionButton title={'R&D'} selected={checkInvestorStypeSelected('R&D')}
                                            clickHandler={() => handleInvestorTypeChange('R&D')} isSmall={true}/>
                        <SelectOptionButton title={'Large-Enterprise'} selected={checkInvestorStypeSelected('Large-Enterprise')}
                                            clickHandler={() => handleInvestorTypeChange('Large-Enterprise')} isSmall={true}/>
                        <SelectOptionButton title={'Other'} selected={checkInvestorStypeSelected('Other')}
                                            clickHandler={() => handleInvestorTypeChange('Other')} isSmall={true}/>
                    </div>
                    { isFieldError.isInvesmentType && <p className="error-message special-error">Select an Investment Type</p> }
                </div>
            </div>
            <div className={'register-button-and-field-container'}>
                <div></div>
                <CustomTextInputInline  large={true} isOther={true} title={'investmentTypeDescription'}
                                        isNormal={true} placeholder={'Please mention if other'}
                                        inputValue={formData.investmentTypeDescription}
                                        setValue={handleInvestmentTypeDescription} id={'investmentTypeDescription'}
                                        htmlFor={'investmentTypeDescription'} isDisable={!formData.investmentType.some(inv => inv === 'Other')}/>
            </div>

            <div className={'investment-industry-preference-container'}>
                <div className={'investment-industry-preference-label'}>
                    <label htmlFor={'investmentType'} className="cus-label" style={{ textAlign: 'left' }}>
                        Investment Industry Preference
                    </label>
                </div>
                <div className={'investment-select-container'}>
                    <div className={'investment-select-input-container'}>
                        <Tooltip title="Select Primary Industry" color='#264294' placement="bottom">
                            <Select style={{ width: '100%' }} value={formData.investmentIndustryPreference1} 
                            onChange={handleInvestmentIndustry1} size={'large'} placeholder={'Select Industry'}>
                                {investmentIndustryPreferenceList.map(industry => {
                                    return <Option value={ industry } key={industry}>{ industry }</Option>
                                })}
                            </Select>
                        </Tooltip>
                        { isFieldError.isInvestmentIndustryPreference1 && <p className="error-message">Select an Industry</p> }
                    </div>
                    <div className={'investment-select'}>
                        <Tooltip title="Select Industry (Optional)" color='#264294' placement="bottom">
                            <Select style={{ width: '100%' }} value={formData.investmentIndustryPreference2}
                            onChange={handleInvestmentIndustry2} size={'large'}
                                    placeholder={'Select Industry (Optional)'}>
                                {investmentIndustryPreferenceList.map(industry => {
                                    return <Option value={ industry } key={industry}>{ industry }</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </div>
                    <div className={'investment-select'}>
                        <Tooltip title="Select Industry (Optional)" color='#264294' placement="bottom">
                            <Select style={{ width: '100%' }} onChange={handleInvestmentIndustry3}
                            value={formData.investmentIndustryPreference3} size={'large'}
                                    placeholder={'Select Industry (Optional)'}>
                                {investmentIndustryPreferenceList.map(industry => {
                                    return <Option value={ industry } key={industry}>{ industry }</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </div>
                    <div className={'investment-select'}>
                        <Tooltip title="Select Industry (Optional)" color='#264294' placement="bottom">
                            <Select style={{ width: '100%' }} onChange={handleInvestmentIndustry4} size={'large'}
                                value={formData.investmentIndustryPreference4} placeholder={'Select Industry (Optional)'}>
                                {investmentIndustryPreferenceList.map(industry => {
                                    return <Option value={ industry } key={industry}>{ industry }</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestorRegisterFormPart3;