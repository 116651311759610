import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import {Dna} from "react-loader-spinner";

import InvestorTable from "../../components/investor/InvestorTable";
import AdminInvestorTable from "../../components/investor/AdminInvestorTable";
import SortingBar from "../../components/common/sortingBar/SortingBar";

import { DEV_URL } from '../../configuration/config';

import './InvestorScreen.css';

const InvestorScreen = () => {
    const [isAdmin, setIsAdmin] = useState(true);
    const [searchTerm ,setSearchTerm] = useState('');
    const user = useSelector(state => state.auth.user);
    const [investors, setInvestors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const fetchInvestors = async () => {
            try{
                const { data } = await axios.get(`${DEV_URL}/api/investors`);
                setInvestors(data.investors);
            }catch (error){
                console.log(error);
            }
        }
        fetchInvestors();

        if(user.id !== undefined) {
            if (user.type === 3) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }

        setTimeout(() => {
            setIsLoading(false);
        },1500);
    },[]);

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleSearchClick = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`${DEV_URL}/api/investors/search?search=${searchTerm}`);
            setInvestors(data.investors);
            setIsLoading(false);
        }catch (error){
            console.log(error);
        }
    }

    const handleSortClick = async (industry, investment) => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`${DEV_URL}/api/investors/filter?industry=${encodeURIComponent(industry)}&investment=${encodeURIComponent(investment)}`);
            setInvestors(data.investors);
            setIsLoading(false);
        }catch (error){
            console.log(error);
        }
    }

    const cancelSortClick = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`${DEV_URL}/api/investors`);
            setInvestors(data.investors);
            setIsLoading(false);
        }catch (error){
            console.log(error);
        }
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'investor-screen'}>
            <div className={'investor-header'}>
                <p className={'investor-title'}>Investors</p>
            </div>
            <div className={'investor-divider'}></div>
            {isAdmin && <SortingBar searchTerm={searchTerm} handleTerm={handleSearchTerm}
                                    handleSearchClick={handleSearchClick} handleSortClick={handleSortClick} 
                                    cancelSortClick={cancelSortClick}/>}
            <div className={'investor-container'}>
                {isAdmin ? <AdminInvestorTable investors={investors}/> : <InvestorTable investors={investors}/>}
            </div>
        </div>
    )
}

export default InvestorScreen;