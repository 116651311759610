import React, { useState } from 'react';

import './NotificationTable.css';

const NotificationTable = ({ notifications, userType, readNotification }) => {
    const [expandedNotification, setExpandedNotification] = useState(0);

    const notificationClickHandler = (id) => {
        if(expandedNotification === id){
            setExpandedNotification(0);
        }else{
            readNotification(id);
            setExpandedNotification(id);
        }
    }

    const handleDecideRowStyle = (isRead) => {
        if(userType === 'Admin' && !isRead){
            return 'admin-unread-notification-row';
        }else if(userType === 'Company' && !isRead){
            return 'company-unread-notification-row';
        }else if(userType === 'Investor' && !isRead){
            return 'investor-unread-notification-row';
        }
    }

    return(
        <div className={'notification-table'}>
            <div className={`notification-row ${userType === 'Company' && 'notification-header-row-company'}
            ${userType === 'Investor' && 'notification-header-row-investor'} 
             ${userType === 'Admin' && 'notification-header-row-admin' }`}>
                <p className={'notification-table-header'}>Time</p>
                <p className={'notification-table-header'}>From</p>
                <p className={'notification-table-header'}>Subject</p>
            </div>
            <div className={'notification-detail'}>
                {notifications.map((notification, index) => {
                    return(
                        <div key={index}>
                            <div className={`notification-row ${handleDecideRowStyle(notification.isRead)}`}
                                 onClick={() => notificationClickHandler(notification.id)}>
                                <p className={'notification-text'}>{ notification.time }</p>
                                <p className={'notification-text'}>{ notification.from }</p>
                                <p className={'notification-text'}>{ notification.subject }</p>
                            </div>
                            {expandedNotification === notification.id && <div className={'notification-expand-container'}>
                                <p className={'notification-expand-header'}>{ notification.subject }</p>
                                <p className={'notification-expand-text'}>{ notification.description }</p>
                            </div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default NotificationTable;