import React from "react";
import {FormOutlined} from "@ant-design/icons";

import './NotificationButton.css';

const NotificationButton = ({ handleCreateNotification }) => {
    return(
        <div className={'notification-add-button'} onClick={handleCreateNotification}>
            <FormOutlined className={'notification-icon'}/>
            <p className={'notification-add-button-text'}>New Message</p>
        </div>
    )
}

export default NotificationButton;