import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { message } from "antd";
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Dna } from "react-loader-spinner";

import { DEV_URL } from '../../configuration/config';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalanderScreen.css';

const localizer = momentLocalizer(moment);

const CalanderScreen = () => {
    const user = useSelector(state => state.auth.user);
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const fetchEventsData = async () => {
            if(user.id !== undefined){
                try{
                    const { data } = await axios.get(`${DEV_URL}/api/meetings/calander/${user.id}`);
                    const calanderEvents = data.calanderMeetingList;
                    const finalEvents = [];

                    for(let newEvent of calanderEvents){
                        const startDateString = newEvent.start;
                        const endDateString = newEvent.end;

                        const [year, month, day, hour, minute] = startDateString
                            .split(/[-T:]/)
                            .map((part, index) => {
                                if (index === 1) return parseInt(part, 10) - 1;
                                return parseInt(part, 10);
                            });

                        const [year1, month1, day1, hour1, minute1] = endDateString
                            .split(/[-T:]/)
                            .map((part, index) => {
                                if (index === 1) return parseInt(part, 10) - 1;
                                return parseInt(part, 10);
                            });

                        let tempEvent = {
                            title: newEvent.title,
                            start: new Date(year, month, day, hour, minute),
                            end: new Date(year1, month1, day1, hour1, minute1)
                        }
                        finalEvents.push(tempEvent);
                    }
                    //console.log(finalEvents);
                    setEvents(finalEvents);
                }catch (error){
                    console.log(error);
                }
            }
        }
        fetchEventsData();

        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    },[user]);


    console.log(events)

    const handleEventClick = (event) => {
        // Handle the event click here
        console.log(event);
        message.success(event.title, 4);
    };

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'calander-screen'}>
            <div className={'calander-header'}>
                <p className={'calander-title'}>Calendar</p>
            </div>
            <div className={'calander-divider'}></div>
            <div className="dark-calendar">
                <Calendar
                    className={'calander'}
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={handleEventClick}
                    views={['day']}
                    defaultView="day"
                    date={new Date(2024, 5, 25)}
                    toolbar={false}
                    min={new Date(2024, 5, 25, 16, 0)}
                    max={new Date(2024, 5, 25, 19, 0)}
                />
            </div>
        </div>
    )
}

export default CalanderScreen;