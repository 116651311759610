import React, { useState } from "react";
import { FaInfoCircle } from 'react-icons/fa';

import './CompanyTable.css';

const AdminCompanyTable = ({ companies, moreDetailClick }) => {
    const [expandedCompany, setExpandedCompany] = useState(0);

    const companyClickHandler = (id) => {
        if(expandedCompany === id){
            setExpandedCompany(0);
        }else{
            setExpandedCompany(id);
        }
    }

    return(
        <div className={'company-table'}>
            <div className={`admin-company-row admin-company-header-row`}>
                <p className={'company-table-header'}>Company</p>
                <p className={'company-table-header'}>Industry</p>
                <p className={'company-table-header'}>Company Type</p>
                <p className={'company-table-header'}>Business Stage</p>
                <p className={'company-table-header'}>Status</p>
            </div>
            <div className={'company-detail'}>
                {companies.map((company, index) => {
                    return(
                        <div key={index}>
                            <div className={`admin-company-row ${company.isPending && 'admin-pending-row'}`}>
                                <p className={'company-text company-name'} onClick={() => companyClickHandler(company.id)}>
                                    { company.company }
                                    {expandedCompany !== company.id ? <FaInfoCircle className="company-expand-icon"/> :
                                    <FaInfoCircle className="company-expand-icon"/> }
                                </p>
                                <p className={'company-text'}>{ company.industry }</p>
                                <p className={'company-text'}>{ company.companyType }</p>
                                <p className={'company-text'}>{ company.businessStage }</p>
                                <p className={'company-text'}>{ company.isPending ? 'Pending' : 'Approved' }</p>
                            </div>
                            {expandedCompany === company.id && <div className={'company-expand-main-container'}>
                                <div className={'company-expand-container'}>
                                    <p className={'company-expand-header'}>Elevator Pitch</p>
                                    <p className={'company-expand-text'}>{ company.elevationPitch }</p>
                                </div>
                                <div className={'company-link-container'}>
                                    <p className={'company-link'} onClick={() => moreDetailClick(company.id)}>More Details</p>
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AdminCompanyTable;