import React from "react";
import { Link, useNavigate } from "react-router-dom";

import RegisterAs from "../../components/register/RegisterAs";

import './RegisterScreen.css';

import profile from '../../assets/register/profile-new.png';
import company from '../../assets/register/group-updated.png';
import logo from '../../assets/common/logo-new-1.jpg';
import csslLogo from '../../assets/signuup/CSSl-logo.png';
import motLogo from '../../assets/signuup/MOT-logo.png';
import mastercardLogo from '../../assets/signuup/mastercard-logo.png';
import homeScreenLogo from '../../assets/signuup/home-screen-logo.png';

const RegisterScreen = () => {
    const navigate = useNavigate();

    const navigateToCompanyRegistration = () => {
        navigate('/sign-up-as?type=company');
    }

    const navigateToInvestorRegistration = () => {
        navigate('/sign-up-as?type=investor')
    }

    return(
        <div className={'register-page-container'}>
            <div className={'register-master-container'}>
                <div className={'register-sub-container'}>
                    <img alt={'home-screen-logo'} src={homeScreenLogo} className={'register-screen-complete-logo'}/>
                </div>
                <div className={'register-container'}>
                    <RegisterAs title={'Sign up as Company'} image={profile} className={'register-as'}
                                onClickItem={navigateToCompanyRegistration}/>
                    <RegisterAs title={'Sign up as Investor'} image={company} className={'register-as'}
                                onClickItem={navigateToInvestorRegistration}/>
                    <p className={'link-text'}>
                        Already have an account? <Link to="/log-in" className="link"> Login!</Link>
                    </p>
                </div>
            </div>
            <div className={'register-description-container'}>
                <p className={'register-description-text'}>A joint industry initiative connecting
                 Sri Lankan startup and scale-up companies with investors, to support their next growth phase and global expansion.</p>
            </div>
            <div className={'register-page-footer'}>
                <div className={'register-page-footer-container'}></div>
                <p className={'login-powered-test'}>Powered By Engenuity Ai</p>
            </div>
        </div>
    )
}

export default RegisterScreen;