import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useSelector } from 'react-redux';
import { Dna } from 'react-loader-spinner';

import CompanyTable from "../../components/company/CompanyTable";
import AdminCompanyTable from "../../components/company/AdminCompanyTable";
import SortingBar from "../../components/common/sortingBar/SortingBar";
import { DEV_URL } from '../../configuration/config';

import './CompanyScreen.css';

const CompanyScreen = () => {
    const navigate = useNavigate();
    const [searchTerm ,setSearchTerm] = useState('');
    const user = useSelector(state => state.auth.user);
    const [isAdmin, setIsAdmin] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchCompanies = async () => {
            try{
                const companies = await axios.get(`${DEV_URL}/api/companies`);
                setCompanies(companies.data.companies);
            }catch (error){
                console.log(error);
            }
        }
        fetchCompanies();

        if(user.id !== undefined) {
            if (user.type === 3) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
        setTimeout(() => {
            setIsLoading(false);
        },1500);
    },[]);

    const handleMoreDetailClick = (id) => {
        navigate(`/company/${id}`);
    }

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleSearchClick = async () => {
        try{
            const response = await axios.get(`${DEV_URL}/api/companies/search?search=${searchTerm}`);
            setCompanies(response.data.companies);
        }catch (error){
            console.log(error);
        }
    }

    const handleSortClick = async (industry, investment) => {
        try{
            const response = await axios.get(`${DEV_URL}/api/companies/filter?investment=${encodeURIComponent(investment)}&industry=${encodeURIComponent(industry)}`);
            setCompanies(response.data.companies);
        }catch (error){
            console.log(error);
        }
    }

    const cancelSortClick = async () => {
        try{
            const companies = await axios.get(`${DEV_URL}/api/companies`);
            setCompanies(companies.data.companies);
        }catch (error){
            console.log(error);
        }
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'company-screen'}>
            <div className={'company-header'}>
                <p className={'company-title'}>Companies</p>
            </div>
            <div className={'company-divider'}></div>
            {user.type !== 1 && <SortingBar searchTerm={searchTerm} handleTerm={handleSearchTerm}
                        handleSearchClick={handleSearchClick} handleSortClick={handleSortClick} 
                        cancelSortClick={cancelSortClick}/>}
            <div className={'company-container'}>
                {isAdmin ? <AdminCompanyTable companies={companies} moreDetailClick={handleMoreDetailClick}/>
                    : <CompanyTable companies={companies} moreDetailClick={handleMoreDetailClick}/>}
            </div>
        </div>
    )
}

export default CompanyScreen;