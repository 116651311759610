import React from "react";
import { Button } from 'antd';

import './CustomButton.css';

const CustomButton = ({ title, color, buttonClick, isRegisterButton = false }) => {

    const buttonColorStyle = {
        backgroundColor: color,
        color: '#fff',
    };

    const buttonStyle = {
        borderWidth: '2px',
        borderColor: 'white',
        color: 'white',
        fontSize: '18px',
        backgroundColor: color
    };

    return(
        <div>
            { isRegisterButton ?
                <Button style={buttonStyle} onClick={ buttonClick } className={'custom-register-button'}>{ title }</Button>
                : <Button style={buttonColorStyle} onClick={ buttonClick } className={'custom-button'}>{ title }</Button>}
        </div>
    )
}

export default CustomButton;