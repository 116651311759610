import React, { useState, useEffect } from "react";
import { FormOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useSelector } from 'react-redux';
import axios from "axios";
import { Dna } from "react-loader-spinner";

import ScheduleTable from "../../components/schedule/ScheduleTable";
import RequestedMeetingsTable from "../../components/schedule/RequestedMeetingTable";
import InvestorAcceptMeetingModel from "../../components/schedule/InvestorAcceptMeetingModel";
import InvestorRejectMeetingModal from "../../components/schedule/InvestorRejectMeetingModal";
import { DEV_URL } from '../../configuration/config';

import './ScheduleScreen.css';

const initialAvailableTimes = ['16.15', '16.30', '16.45', '17.00', '17.15', '17.30', '17.45', '18.00'];

const ScheduleScreen = () => {
    let user = useSelector(state => state.auth.user);

    const [userType, setUserType] = useState('Investor');
    const [selectedDate, setSelectedDate] = useState('2024-06-25');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedInvestor, setSelectedInvestor] = useState('');
    const [noteToInvestor, setNoteToInvestor] = useState('');

    const [isAddClick, setIsAddClick] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isShowModel, setIsShowModel] = useState(false);
    const [acceptMeetingId, setAcceptMeetingId] = useState('');
    const [isRejectModelShow, setIsRejectModalShow] = useState(false);
    const [rejectMeetingId, setRejectMeetingId] = useState('');
    const [acceptMeetingType, setAcceptMeetingType] = useState('Physical');
    const [acceptMeetingLocation, setAcceptMeetingLocation] = useState('Event');
    const [noteToCompany, setNoteToCompany] = useState('');

    const [availableTime, setAvailableTimes] = useState([]);
    const [availableInvestors, setAvailableInvestors] = useState([]);

    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        setIsLoading(true);

        if(user.type === 1){
            setUserType('Company');
            fetchCompanyMeetings();
        }else{
            setUserType('Investor')
            fetchInvestorMeetings();
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 200);
    },[user]);

    useEffect(() => {
        filterPastTimes();
    },[selectedDate]);

    useEffect(() => {
        if(user.type === 1){
            fetchAvailableInvestors();
        }
    },[selectedTime, selectedDate]);

    const fetchCompanyMeetings = async () => {
        if(user.id !== undefined){
            try{
                const { data } = await axios.get(`${DEV_URL}/api/meetings/user/${user.id}`);
                const meetings = data.meetings;

                const finalMeetingList = [];
                for(let meeting of meetings){
                    let tempMeeting = {
                        id: meeting.id,
                        status: meeting.status,
                        day: meeting.day,
                        startingTime: meeting.startingTime,
                        endingTime: meeting.endingTime,
                        company: meeting.company.companyName,
                        investor: `${meeting.investor.firstName} ${meeting.investor.lastName}`,
                        room: meeting.room,
                        roomType: meeting.roomType,
                        noteToCompany: meeting.noteToCompany
                    }
                    finalMeetingList.push(tempMeeting);
                }
                setEvents(finalMeetingList);
            }catch (error){
                console.log(error);
            }
        }
    }

    const fetchInvestorMeetings = async () => {
        if(user.id !== undefined){
            try{
                const { data } = await axios.get(`${DEV_URL}/api/meetings/user/${user.id}?day=${encodeURIComponent(selectedDate)}`);
                const meetings = data.meetings;

                const finalMeetingList = [];
                for(let meeting of meetings){
                    let tempMeeting = {
                        id: meeting.id,
                        status: meeting.status,
                        day: meeting.day,
                        startingTime: meeting.startingTime,
                        endingTime: meeting.endingTime,
                        companyName: meeting.company.companyName,
                        companyId: meeting.company.id,
                        investor: `${meeting.investor.firstName} ${meeting.investor.lastName}`,
                        room: meeting.room,
                        roomType: meeting.roomType,
                        noteToInvestor: meeting.noteToInvestor
                    }
                    finalMeetingList.push(tempMeeting);
                }
                console.log(finalMeetingList)

                setRequestedMeetings(finalMeetingList);
            }catch (error){
                console.log(error);
            }
        }
    }

    const filterPastTimes = () => {
        const today = new Date().toISOString().split('T')[0];
        const currentTime = new Date().getHours();

        if(selectedDate === today){
            let currentAvailableTimes = initialAvailableTimes;
            currentAvailableTimes = currentAvailableTimes.filter(time => parseFloat(time) > currentTime);
            setAvailableTimes(currentAvailableTimes);
        }else{
            setAvailableTimes(initialAvailableTimes);
        }
    }

    const fetchAvailableInvestors = async () => {
        try{
            const { data } = await axios.get(`${DEV_URL}/api/meetings/availability/investor?time=${encodeURIComponent(selectedTime)}&day=${encodeURIComponent(selectedDate)}`);
            setAvailableInvestors(data.availableInvestors)
        }catch (error){
            console.log(error);
        }
    }

    const [events, setEvents] = useState([]);
    const [requestedMeetings, setRequestedMeetings] = useState([]);

    const delineRequest = async (id) => {
        setIsRejectModalShow(true);
        setRejectMeetingId(id);
    }

    const declineCancelHandler = () => {
        setRejectMeetingId('');
        setIsRejectModalShow(false);
    }

    const declineOkHandler = async () => {
        try{
            await axios.put(`${DEV_URL}/api/meetings/reject/${rejectMeetingId}`,{
                message: noteToCompany
            });

            setRejectMeetingId('');
            setIsRejectModalShow(false);

            await fetchInvestorMeetings();
        }catch (error){
            console.log(error);
        }
    }

    const acceptRequest = async (meetingId) => {
        setIsShowModel(true);
        setAcceptMeetingId(meetingId);
    }

    const clickOKInModelHandler = async () => {
        try{
            await axios.put(`${DEV_URL}/api/meetings/accept/${acceptMeetingId}`,{
                room: acceptMeetingType,
                roomType: 'Event',
                noteToCompany: noteToCompany
            });

            setAcceptMeetingId('');
            setAcceptMeetingType('Physical');
            setAcceptMeetingLocation('Event');
            setNoteToCompany('');
            setIsShowModel(false);
        }catch (error){
            console.log(error);
        }
        fetchInvestorMeetings();
    }

    const clickCancelInModelHandler = () => {
        setIsShowModel(false);
        setAcceptMeetingId('');
        setNoteToCompany('');
        setAcceptMeetingType('Physical');
        setAcceptMeetingLocation('Event');
    }

    const handleChangeAcceptMeetingType = (type) => {
        setAcceptMeetingType(type);
    }

    const handleChangeAcceptMeetingLocation = (type) => {
        setAcceptMeetingLocation(type)
    }

    const handleNoteToCompany = (e) => {
        setNoteToCompany(e.target.value);
    }

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    }

    const handleTimeChange = (e) => {
        setSelectedTime(e.target.value);
    }

    const handleInvestorChange = (e) => {
        setSelectedInvestor(e.target.value);
    }

    const handleNoteToInvestor = (e) => {
        setNoteToInvestor(e.target.value);
    }

    const isDateAndTimeSelected = () => {
        return !(selectedDate === '' || selectedTime === '');
    }

    const isDateSelected = () => {
        return selectedDate !== '';
    }

    const addScheduleClick = async () => {
        if(!isAddClick){
            setIsAddClick(true);
        }else {
            const timeValid = selectedTime.length > 0;
            const investorValid = selectedInvestor.toString().length > 0;

            if(timeValid && investorValid){
                const [hours, minutes] = selectedTime.split(".").map(Number);
                let newHours = hours;
                let newMinutes = (minutes + 15) % 60;

                if (newMinutes === 0) {
                    newHours = (newHours + 1) % 24;
                }

                let endingTime = `${newHours}.${newMinutes.toString().padStart(2, "0")}`;

                try{
                    await axios.post(`${DEV_URL}/api/meetings/schedule`, {
                        day: selectedDate,
                        startingTime: selectedTime,
                        endingTime: endingTime,
                        companyUserId: user.id,
                        investorId: selectedInvestor,
                        noteToInvestor: noteToInvestor
                    });
                }catch (error){
                    console.log(error);
                }

                fetchCompanyMeetings();

                setSelectedTime('');
                setSelectedInvestor('');
                setSelectedDate('');
                setNoteToInvestor('');
                setIsAddClick(false);
            }else {
                message.error('Select all fields before enter');
            }
        }
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'schedule-screen'}>
            <div className={'schedule-header'}>
                <p className={'schedule-title'}>{userType === 'Company' ? 'Schedule Meetings': 'Meeting Requests'}</p>
            </div>
            <div className={'schedule-divider'}></div>
            {isShowModel && <InvestorAcceptMeetingModel clickCancel={clickCancelInModelHandler} clickOk={clickOKInModelHandler}
                                                        isOpen={isShowModel} acceptMeetingType={acceptMeetingType}
                                                        acceptMeetingLocation={acceptMeetingLocation}
                                                        changeAcceptMeetingLocation={handleChangeAcceptMeetingLocation}
                                                        changeAcceptMeetingType={handleChangeAcceptMeetingType} note={noteToCompany}
                                                        changeNote={handleNoteToCompany}/>}
            {isRejectModelShow && <InvestorRejectMeetingModal note={noteToCompany} changeNote={handleNoteToCompany}
                                                              isOpen={isRejectModelShow} clickCancel={declineCancelHandler}
                                                              clickOk={declineOkHandler}/>}
            {userType === 'Investor' && <>
                <RequestedMeetingsTable meetings={requestedMeetings} delineRequest={delineRequest} acceptRequest={acceptRequest}/>
            </>}
            {userType === 'Company' && <>
                <div className={'schedule-container'}>
                    <ScheduleTable events={events}/>
                </div>
                {isAddClick && <div className={'new-schedule-meeting-container'}>
                    <div className={'new-schedule-meeting-title-container'}>
                        <p className={'new-schedule-meeting-title'}>New Meeting</p>
                    </div>
                    <div className={'new-schedule-container'}>
                        {/*<div className={'custom-date-selector-container'}>
                            <input type="date" id="date" name="date" className={'custom-date-selector'} value={selectedDate}
                                   onChange={handleDateChange} min={today}/>
                        </div>*/}
                        <select value={selectedTime} onChange={handleTimeChange} placeholder={'Time'} className="custom-select"
                                disabled={!isDateSelected()}>
                            <option value="" disabled hidden>Time</option>
                            {availableTime.map((time, index) => {
                                return (
                                    <option value={time} className={'custom-option'} key={index}>{time}</option>
                                )
                            })}
                        </select>
                        <select onChange={handleInvestorChange} placeholder={'Investor'} className="custom-select"
                                disabled={!isDateAndTimeSelected()} value={selectedInvestor}>
                            <option value="" disabled hidden>Investor</option>
                            {availableInvestors.map((investor, index) => {
                                return (
                                    <option value={investor.id} className={'custom-option'} key={index}>
                                        {investor.firstName}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className={'new-schedule-note-container'}>
                        <textarea value={noteToInvestor} onChange={handleNoteToInvestor}
                                  placeholder={'* Note to Investor '}
                                  className={'new-schedule-note'}>
                        </textarea>
                    </div>
                </div>}
                <div className={'schedule-add-button'} onClick={addScheduleClick}>
                    <FormOutlined className={'schedule-icon'}/>
                    <p className={'schedule-add-button-text'}>{!isAddClick ? 'Add Schedule' : 'Save Changes'}</p>
                </div>
            </>}
        </div>
    )
}

export default ScheduleScreen;