import React, { useState } from 'react';
import { FaBan, FaFilter } from "react-icons/fa";

const MeetingSortingBar = ({ companies, investors, handleSortClick, cancelSort }) => {
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedInvestorId, setSelectedInvestorId] = useState('');

    return(
        <div>
            <div className={'sort-container'}>
                <div className="icon-container" onClick={() =>  handleSortClick(selectedCompanyId, selectedInvestorId)}>
                    <FaFilter className="filter-icon"/>
                </div>

                <select className="form-select" id="company" value={selectedCompanyId}
                        onChange={(e) => setSelectedCompanyId(e.target.value)}>
                    <option value="" disabled hidden>Select Company</option>
                    {companies.map((company, index) => {
                        return <option value={company?.id} key={index}>{company?.company}</option>
                    })}
                </select>

                <select className="form-select" id="investor" value={selectedInvestorId}
                        onChange={(e) => setSelectedInvestorId(e.target.value)}>
                    <option value="" disabled hidden>Select Investor</option>
                    {investors.map((investor, index) => {
                        return <option value={investor?.id} key={index}>{investor?.investor}</option>
                    })}
                </select>

                <div className="icon-container" onClick={() =>  cancelSort()}>
                    <FaBan className="close-icon"/>
                </div>
            </div>
        </div>
    )
}

export default MeetingSortingBar;