import React from 'react';

import './CustomBarChart.css';

const COLORS = ['#b32d7d', '#29256a', '#7f0992', '#00b16e'];

const CustomBarChart = ({ data }) => {
    const transformDataIntoChartData = () => {
        return data.map((dataItem, index) => {
            return {
                name: dataItem.name,
                value: dataItem.value,
                color: COLORS[index]
            }
        });
    }

    const totalValueCalculator = () => {
        let sum = 0;
        data.forEach(dataItem => {
            sum += dataItem.value;
        })
        return sum;
    }

    return(
        <div className={'custom-bar-chart'}>
            <div className={'chart-container'}>
                {transformDataIntoChartData().map((chartItem, index) => (
                    <div key={index} className="chart-part" style={{
                            width: `${(chartItem.value / totalValueCalculator()) * 100}%`,
                            backgroundColor: chartItem.color || 'blue',
                        }}
                    ></div>
                ))}
            </div>
            <div className={'chart-legend-container'}>
                {transformDataIntoChartData().map((legendItem, index) => (
                    <div className={'chart-legend-item'} key={index}>
                        <p className={'chart-legend-item-value'}>{legendItem.value}</p>
                        <p className={'chart-legend-item-name'}>{legendItem.name}</p>
                        <div style={{backgroundColor: legendItem.color}} className={'chart-legend-item-tooltip'}></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomBarChart;