import React, { useState } from 'react';
import { Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import CustomTextInput from "../../components/common/textInput/CustomTextInput";

import './TemporyLoginScreen.css';

const TemporyLogicScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const loginHandler = () => {
        if(username === 'b2b' && password === 'Test123#'){
            navigate('/log-in');
        }else{
            message.error('Enter valid username password pair')
        }
    }

    return(
        <div className={'temporary-login-screen'}>
            <div className={'temporary-login-container'}>
                <CustomTextInput inputValue={username} setValue={(e) => setUsername(e.target.value)} placeholder={'Enter Username'}/>

                <CustomTextInput inputValue={password} setValue={(e) => setPassword(e.target.value)}
                                 placeholder={'Enter Password'} isPassword={true}/>

                <Button onClick={loginHandler}>Login</Button>
            </div>
        </div>
    )
}

export default TemporyLogicScreen;