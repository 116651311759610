import React from "react";
import { Image } from "antd";

import './NotApprovedYet.css';

import tired from '../../assets/dashboard/tired.png';
import notApproved from '../../assets/dashboard/admin-accept-new.png'

const NotApprovedYet = () => {
    return(
        <div className={'not-approved-yet-container'}>
            <div className={'not-approved-yet-image-container'}>
                <Image src={notApproved} preview={false} className={'not-approved-yet-image'}/>
            </div>
            <div className={'not-approved-yet-text-container'}>
                <p className={'not-approved-yet-text'}>Thank You for registering</p>
                <p className={'not-approved-yet-text'}>Please wait until the administrator accepts your request</p>
            </div>
        </div>
    )
}

export default NotApprovedYet;