import React from "react";
import { Input } from "antd";

import './CustomTextInput.css';

const CustomTextInput = ({ inputValue, setValue, placeholder, isPassword = false }) => {
    return(
        <div className={'input-container'}>
            { isPassword ?
                <Input.Password
                    value={inputValue}
                    onChange={setValue}
                    className={'custom-input'}
                    placeholder={placeholder}/>
                : <Input value={inputValue}
                       onChange={setValue}
                         className={'custom-input'}
                         placeholder={placeholder}/> }
        </div>
    )
}

export default CustomTextInput;