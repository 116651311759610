import React, { useState } from "react";
import { Button } from 'antd';
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import './RequestedMeetingsTable.css';

const RequestedMeetingsTable = ({ meetings, delineRequest, acceptRequest }) => {
    const navigate = useNavigate();

    const [expandedMeetingId, setExpandedMeetingId] = useState('');

    const clickMoreDetail = (id) => {
        navigate(`/company/${id}?type=Request`)
    }

    const handleExpandedMeetingId = (id) => {
        if(expandedMeetingId === id){
            setExpandedMeetingId('');
        }else {
            setExpandedMeetingId(id);
        }
    }

    return(
        <div>
            {meetings.map(meeting => {
                return (
                    <div key={meeting.id} className="requested-meeting-item">
                        <div className="requested-meeting-description">
                            <div className="requested-meeting-header-container">
                                <p className="requested-meeting-header">{meeting.companyName} | {meeting.room}</p>
                                <p className="requested-meeting-header-link" 
                                    onClick={() => clickMoreDetail(meeting.companyId)}>
                                    More Details
                                </p>
                            </div>
                            <div className={'requested-meeting-row-container'}>
                                <p className="requested-meeting-period">{meeting.day} | {meeting.startingTime} - {meeting.endingTime}</p>
                                <FaEye className={'requested-meeting-icon'} onClick={() => handleExpandedMeetingId(meeting.id)}/>
                                <div></div>
                            </div>
                            {expandedMeetingId === meeting.id && <div className={'requested-meeting-expanded-container'}>
                                <p className="requested-meeting-note-title">Note From Company: </p>
                                <p className="requested-meeting-note">{meeting.noteToInvestor}</p>
                            </div>}
                        </div>
                        {meeting.status !== 'Accepted' && <div className="requested-meeting-button-container">
                            <Button className="request-handle-button request-accept-button" 
                                onClick={() => acceptRequest(meeting.id)}>Accept</Button>
                            <Button className="request-handle-button request-reject-button" 
                                onClick={() => delineRequest(meeting.id)}>Decline</Button>
                        </div>}
                    </div>
                )
            })}
        </div>
    )
}

export default RequestedMeetingsTable;