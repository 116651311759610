import { LOG_IN_USER, CHANGE_STATUS_AS_REGISTERED } from '../actions/LoginAction';

const initState = {
    user: {},
    token: ''
}

const LoginReducer = (state = initState, action) => {
    switch(action.type){
        case LOG_IN_USER:
            return {
                user: action.payload.user,
                token: action.payload.token
            }
        case CHANGE_STATUS_AS_REGISTERED:
            const newUser = state.user;
            newUser.status = 'Registered';
            return {
                user: newUser,
                token: state.token
            }    
    }
    return state;
}

export default LoginReducer;