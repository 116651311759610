import React, { useState, useEffect } from 'react';
import { Badge, Image, Tooltip } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import './CustomNavbar.css';

import digiEconTransparent from '../../../assets/dashboard/digiecon-nav-bar-logo.png';
import { DEV_URL } from '../../../configuration/config';

import dashboardSelect from '../../../assets/dashboard/new-icon/category-select.png';
import dashboard from '../../../assets/dashboard/new-icon/category-not-select.png';
import investorCompany from '../../../assets/dashboard/new-icon/company-not-select.png';
import investorCompanySelect from '../../../assets/dashboard/new-icon/company-select.png';
import calander from '../../../assets/dashboard/new-icon/calender-not-select.png';
import calanderSelect from '../../../assets/dashboard/new-icon/calender-select.png';
import notification from '../../../assets/dashboard/new-icon/notification-not-select.png';
import notificationSelect from '../../../assets/dashboard/new-icon/notification-select.png';
import profile from '../../../assets/dashboard/new-icon/profile-not-select.png';
import profileSelect from '../../../assets/dashboard/new-icon/profile-select.png';
import schedule from '../../../assets/dashboard/new-icon/meeting-not-select.png';
import scheduleSelect from '../../../assets/dashboard/new-icon/meeting-select.png';


const CustomNavbar = ({ customClickHandler = null }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    
    let user = useSelector(state => state.auth.user);
    const [userType, setUserType] = useState('Admin');
    const [isAuth, setIsAuth] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const [unacceptedInvestorCount, setUnacceptedInvestorCount] = useState(0);
    const [unacceptedCompanyCount, setUnacceptedCompanyCount] = useState(0);
    const [pendingMeetingCount, setPendingMeetingCount] = useState(0);

    useEffect(() => {
        let userLocal = localStorage.getItem('user');
        let userObj = JSON.parse(userLocal);
        if(userObj){
            user = userObj;
        }else{
            navigate('/log-in');
        }


        if(user.status === "Not Registered" || user.status === 'Registered'){
            setIsAuth(false);
        }

        if(user.type === 1){
            setUserType('Company');
        }else if(user.type === 2){
            setUserType('Investor');
        }else {
            setUserType('Admin');
        }

        const fetchUnacceptedCompanies = async () => {
            const { data } = await axios.get(`${DEV_URL}/api/companies/count`);
            setUnacceptedCompanyCount(data.count);
        }

        const fetchUnacceptedInvestors = async () => {
            const { data } = await axios.get(`${DEV_URL}/api/investors/count`);
            setUnacceptedInvestorCount(data.count);
        }

        const fetchUnreadedNotifications = async () => {
            const { data } = await axios.get(`${DEV_URL}/api/notification/count?user=${user.displayName}`);
            setUnreadNotificationCount(data.count);
        }

        const fetchAllPendingMeeting = async () => {
            const { data } = await axios.get(`${DEV_URL}/api/meetings/count`);
            setPendingMeetingCount(data?.count);
        }

        fetchUnacceptedCompanies();
        fetchUnacceptedInvestors();
        fetchUnreadedNotifications();
        fetchAllPendingMeeting();

        switch (currentPath){
            case '/':
                setSelectedMenuItem(0);
                break;
            case '/investor':
                setSelectedMenuItem(1);
                break;
            case '/company':
                setSelectedMenuItem(2);
                break;
            case '/schedule':
                setSelectedMenuItem(3);
                break;
            case '/admin-schedule':
                setSelectedMenuItem(4);
                break;
            case '/calander':
                setSelectedMenuItem(5);
                break;
            case '/notification':
                setSelectedMenuItem(6);
                break;
            case '/profile':
                setSelectedMenuItem(7);
                break;
        }
    },[]);

    const handleMenuItemClick = (itemIdx) => {
        setSelectedMenuItem(itemIdx);
        if(itemIdx === 0){
            navigate('/');
        }else if(itemIdx === 1){
            setUnacceptedInvestorCount(0);
            navigate('/investor');
        }else if(itemIdx === 2){
            setUnacceptedCompanyCount(0);
            navigate('/company');
        }else if(itemIdx === 3){
            navigate('/schedule');
        }else if(itemIdx === 4){
            navigate('/admin-schedule');
        }else if(itemIdx === 5){
            navigate('/calander');
        }else if(itemIdx === 6){
            setUnreadNotificationCount(0);
            navigate('/notification');
        }else if(itemIdx === 7){
            navigate('/profile')
        }
    }

    const isCompanyOrAdmin = () => {
        return userType === 'Company' || userType === 'Admin';
    }

    const isInvestorOrAdmin = () => {
        return userType === 'Investor' || userType === 'Admin';
    }


    return (
        <div className={`nav-bar ${userType === 'Company' && 'company-nav-bar'} ${userType === 'Investor' && 'investor-nav-bar'}
         ${ userType === 'Admin' && 'admin-nav-bar' }`}>
            <div className={'nav-bar-slider'}>
                <div className={`menu-item ${selectedMenuItem === 0 ? 'selected-menu-item' : 'not-selected-menu-item'}`}
                     onClick={() => handleMenuItemClick(0)}>
                    <Tooltip title={'Dashboard'} placement={'right'}>
                    {selectedMenuItem === 0 ? <Image src={dashboard} className={'menu-icon-select'} preview={false}/> :
                        <Image src={dashboardSelect} className={'menu-icon'} preview={false}/>}
                    </Tooltip>
                </div>

                {isAuth && <>
                    {isCompanyOrAdmin() && <div className={`menu-item ${selectedMenuItem === 1 ? 'selected-menu-item' :
                        'not-selected-menu-item'}`} onClick={() => handleMenuItemClick(1)}>
                        {selectedMenuItem === 1 ?
                            <Tooltip title={'Investors'} placement={'right'}>
                                <Image src={investorCompany} className={'menu-icon-select'} preview={false}/>
                            </Tooltip> :
                            <Tooltip title={'Investors'} placement={'right'}>
                                {userType === 'Admin' ? <Badge count={unacceptedInvestorCount}>
                                    <Image src={investorCompanySelect} className={'menu-icon'} preview={false}/>
                                </Badge> : <Image src={investorCompanySelect} className={'menu-icon'} preview={false}/>}
                            </Tooltip>
                        }
                    </div>}

                    {isInvestorOrAdmin() && <div className={`menu-item ${selectedMenuItem === 2 ? 'selected-menu-item' :
                        'not-selected-menu-item'}`} onClick={() => handleMenuItemClick(2)}>
                        {selectedMenuItem === 2 ?
                            <Tooltip title={'Companies'} placement={'right'}>
                                <Image src={investorCompany} className={'menu-icon-select'} preview={false}/>
                            </Tooltip> :
                            <Tooltip title={'Companies'} placement={'right'}>
                                {userType === 'Admin' ? <Badge count={unacceptedCompanyCount}>
                                    <Image src={investorCompanySelect} className={'menu-icon'} preview={false}/>
                                </Badge> : <Image src={investorCompanySelect} className={'menu-icon'} preview={false}/>}
                            </Tooltip>
                        }
                    </div>}

                    {userType !== 'Admin' &&
                        <div className={`menu-item ${selectedMenuItem === 3 ? 'selected-menu-item' :
                            'not-selected-menu-item'}`} onClick={() => handleMenuItemClick(3)}>
                            <Tooltip title={userType === 'Company' ? 'Schedule a meeting' : 'Meeting Request'} placement={'right'}>
                                {selectedMenuItem === 3 ?
                                    <Image src={schedule} className={'menu-icon-select'} preview={false}/> :
                                    <Image src={scheduleSelect} className={'menu-icon'} preview={false}/>}
                            </Tooltip>
                        </div>}

                    {userType === 'Admin' &&
                        <div className={`menu-item ${selectedMenuItem === 4 ? 'selected-menu-item' :
                            'not-selected-menu-item'}`} onClick={() => handleMenuItemClick(4)}>
                            <Tooltip title={'Schedule Meeting'} placement={'right'}>
                                {selectedMenuItem === 4 ?
                                    <Image src={schedule} className={'menu-icon-select'} preview={false}/> :
                                    <Badge count={pendingMeetingCount}>
                                        <Image src={scheduleSelect} className={'menu-icon'} preview={false}/></Badge>}
                            </Tooltip>
                        </div>}

                    <div
                        className={`menu-item ${selectedMenuItem === 5 ? 'selected-menu-item' : 'not-selected-menu-item'}`}
                        onClick={() => handleMenuItemClick(5)}>
                        <Tooltip title={'Calender'} placement={'right'}>
                            {selectedMenuItem === 5 ?
                                <Image src={calander} className={'menu-icon-select'} preview={false}/> :
                                <Image src={calanderSelect} className={'menu-icon'} preview={false}/>}
                        </Tooltip>
                    </div>

                    <div
                        className={`menu-item ${selectedMenuItem === 6 ? 'selected-menu-item' : 'not-selected-menu-item'}`}
                        onClick={() => handleMenuItemClick(6)}>
                        <Tooltip title={'Notifications'} placement={'right'}>
                            {selectedMenuItem === 6 ?
                                <Image src={notification} className={'menu-icon-select'} preview={false}/> :
                                <Badge count={unreadNotificationCount}>
                                    <Image src={notificationSelect} className={'menu-icon'} preview={false}/>
                                </Badge>}
                        </Tooltip>
                    </div>

                    <div
                        className={`menu-item ${selectedMenuItem === 7 ? 'selected-menu-item' : 'not-selected-menu-item'}`}
                        onClick={() => handleMenuItemClick(7)}>
                        <Tooltip title={'Profile'} placement={'right'}>
                        {selectedMenuItem === 7 ? <Image src={profile} className={'menu-icon-select'} preview={false}/> :
                            <Image src={profileSelect} className={'menu-icon'} preview={false}/>}
                        </Tooltip>
                    </div>
                </>}

            </div>
        </div>
    );
};

export default CustomNavbar;
