import React, { useState } from "react";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import CustomTextInput from "../../components/common/textInput/CustomTextInput";
import CustomButton from "../../components/common/button/CustomButton";

import { DEV_URL } from '../../configuration/config';

import logo from "../../assets/common/logo-new-1.png";

import './ForgotPasswordScreen.css';
import './LoginScreen.css';

const ForgotPasswordScreen = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [stepDescriptionText, setStepDescriptionText] = useState('Enter the Email that is associated with the account');

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');

    const [realOtp, setRealOtp] = useState('');

    const [isEmailError, setIsEmailError] = useState(false);
    const [isOtpError, setIsOtpError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleReEnterPassword = (e) => {
        setReEnterPassword(e.target.value);
    }

    const handleSubmit = async () => {
        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        if(step === 1){
            setIsEmailError(false);

            const isEmailValid = emailRegex.test(email);
            if(!isEmailValid){
                setIsEmailError(true);
            }else {
                try{
                    const { data } = await axios.post(`${DEV_URL}/api/auth/forgot`, {
                        email: email
                    });

                    setRealOtp(data.check.otp);

                    setStep(2);
                    setStepDescriptionText('An OTP has been sent to your email\n' +
                        'check your email and enter the unique code to rest your account')

                }catch (error){
                    message.error('Email does not exist', 5);
                    console.log(error);
                }
            }
        }else if(step === 2){
            setIsOtpError(false);

            const isOtpValid = otp.length === 6;
            if(!isOtpValid){
                setIsOtpError(true);
            }else if(otp === realOtp){
                setStep(3);
                setStepDescriptionText('Enter new Password');
            }else{
                message.error('Entered OTP number is wrong', 5);
            }
        }else{
            setIsPasswordError(false);

            let isPasswordValid = password === reEnterPassword && password.length > 5;
            if(!isPasswordValid){
                setIsPasswordError(true);
            }else{
                try{
                    await axios.post(`${DEV_URL}/api/auth/reset`, {
                        email: email,
                        password: password
                    });
                }catch (error){
                    console.log(error);
                }

                navigate('/log-in');
            }
        }
    }

    return(
        <div className="forgot-password-page">
            <div className={'forgot-password-container'}>
                <div className={'section-1'}>
                    <div className={'login-page-intro-box'}>
                        <img className={'image'} src={logo} alt={'logo'}/>
                        <div>
                            <p className={'login-page-intro-box-header'}>Digiecon</p>
                            <p className={'login-page-intro-box-header'}>Global Investment</p>
                            <p className={'login-page-intro-box-header'}>Summit 2024</p>
                        </div>
                        <div className={'login-page-intro-box-body-container'}>
                            <p className={'login-page-intro-box-body-title'}>25th June 2024</p>
                            <p className={'login-page-intro-box-body-title'}>Cinnamon Grand, Colombo</p>
                            <p className={'login-page-intro-box-body-desc'}>A joint industry initiative connecting Sri
                                Lankan startup
                                and scale-up companies with investors, to support their next growth phase & global
                                expansion</p>
                        </div>
                    </div>
                </div>
                <div className={'section-2'}>
                    <div className="login-form">
                        <p className={'forgot-password-header'}>Forgot Password?</p>

                        <p className={'forgot-password-description'}>{stepDescriptionText}</p>

                        {step === 1 && <div className={'forgot-password-input-container'}>
                            <CustomTextInput inputValue={email} setValue={handleEmailChange} placeholder={'Email'}/>
                            {isEmailError && <p className="login-error-message">Enter valid Email</p>}
                        </div>}

                        {step === 2 && <div className={'forgot-password-input-container'}>
                            <CustomTextInput inputValue={otp} setValue={handleOtpChange} placeholder={'Enter Otp'}/>
                            {isOtpError && <p className="login-error-message">Enter valid Otp</p>}
                        </div>}

                        {step === 3 && <>
                            <div className={'forgot-password-input-container'}>
                                <CustomTextInput isPassword={true} inputValue={password} setValue={handlePassword}
                                                 placeholder={'New Password'}/>
                                {isPasswordError && <p className="login-error-message">Enter a valid password (Password must
                                    contain at least 6 characters)</p>}
                            </div>

                            <div className={'forgot-password-input-container'}>
                                <CustomTextInput inputValue={reEnterPassword} setValue={handleReEnterPassword} isPassword={true}
                                                 placeholder={'Confirm Password'}/>
                                {isPasswordError && <p className="login-error-message">Enter a valid password (Password must
                                    contain at least 6 characters)</p>}
                            </div>
                        </>}

                        <CustomButton buttonClick={handleSubmit} title={'Submit'} color={'#000000'}/>

                        <p className={'cus-link'}>
                            Don't have an account? <Link to="/sign-up" className="login-link">Sign Up!</Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className={'login-page-footer'}>
                {/*<div className={'login-page-logo-container'}>
                    <Image width={100} height={60} preview={false} src={companyLogo}/>
                </div>*/}
                <div className={'login-page-footer-image'}></div>
                <p className={'login-powered-test'}>Powered By Engenuity Ai</p>
            </div>
        </div>
    )
}

export default ForgotPasswordScreen;