import React, { useState } from "react";
import { FaInfoCircle } from 'react-icons/fa';

//import CompanyBottomDrawer from "./CompanyBottomDrawer";

import './CompanyTable.css';

const CompanyTable = ({ companies, moreDetailClick }) => {
    const [expandedCompany, setExpandedCompany] = useState(0);
    const [llsDrawerItem, setLlsDrawerItem] = useState(0);
    /*const [companyData, setCompanyData] = useState({
        companyName: 'Company 1',
        potential: 'Company ABC shows great growth potential with its innovative products/services and strong market demand.' +
            ' Their strategic vision and execution capabilities position them for future success.',
        scalability: 'Company XYZ exhibits impressive scalability potential, thanks to its robust infrastructure ' +
            'and flexible business model. As demand for their products/services continues to rise, they have the capacity ' +
            'to expand operations seamlessly and capture a larger market share.',
        challenges: "Companies frequently encounter challenges such as market competition, regulatory compliance," +
            " and resource constraints in their pursuit of success.",
        un_sdg: [1, 2, 7]
    });*/

    const companyClickHandler = (id) => {
        if(expandedCompany === id){
            setExpandedCompany(0);
        }else{
            setExpandedCompany(id);
        }
    }

    const companyLlsDrawerHandler = (id) => {
        if(llsDrawerItem === id){
            setLlsDrawerItem(0);
        }else{
            setLlsDrawerItem(id);
            // should fetch according data from lms
        }
    }

    const closeDrawer = () => {
        setLlsDrawerItem(0);
    }

    return(
        <div className={'company-table'}>
            {/*<CompanyBottomDrawer isOpen={llsDrawerItem !== 0} closeDrawer={closeDrawer} data={companyData}/>*/}
            <div className={`company-row company-header-row`}>
                <p className={'company-table-header'}>Company</p>
                <p className={'company-table-header'}>Industry</p>
                <p className={'company-table-header'}>Company Type</p>
                <p className={'company-table-header'}>Business Stage</p>
            </div>
            <div className={'company-detail'}>
                {companies.map((company, index) => {
                    return(
                        <div key={index}>
                            <div className={'company-row'}>
                                <p className={'company-text company-name'} onClick={() => companyClickHandler(company.id)}>
                                    { company.company }
                                    {expandedCompany !== company.id ? <FaInfoCircle className="company-expand-icon"/> :
                                    <FaInfoCircle className="company-expand-icon"/> }
                                </p>
                                <p className={'company-text'}>{ company.industry }</p>
                                <p className={'company-text'}>{ company.companyType }</p>
                                <p className={'company-text company-select-item'}>
                                    { company.businessStage }
                                </p>
                            </div>
                            {expandedCompany === company.id && <div className={'company-expand-main-container'}>
                                <div className={'company-expand-container'}>
                                    <p className={'company-expand-header'}>Elevator Pitch</p>
                                    <p className={'company-expand-text'}>{ company.elevationPitch }</p>
                                </div>
                                <div className={'company-link-container'}>
                                    <p className={'company-link'} onClick={() => moreDetailClick(company.id)}>More Details</p>
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CompanyTable;