import React from 'react';
import { Button } from "antd";

import './CompanySelectButton.css';

const CompanySelectButton = ({ title, isApprove }) => {
    return(
        <div>
            <Button onClick={ () => {} }
                    className={ `${isApprove ? 'select-company-button' : 'not-select-company-button'}`}>{ title }</Button>
        </div>
    )
}

export default CompanySelectButton;