import React from 'react';
import { useNavigate } from 'react-router-dom';

import './InvestorTable.css';

const AdminInvestorTable = ({ investors }) => {
    const navigate = useNavigate();

    const handleNavigateSinglePage = (id) => {
        navigate(`/investor/${id}`);
    }

    return(
        <div className={'investor-table'}>
            <div className={`administrator-investor-row admin-investor-header-row`}>
                <p className={'investor-table-header'}>Investor</p>
                <p className={'investor-table-header'}>Industry</p>
                <p className={'investor-table-header'}>Investor Type</p>
                <p className={'investor-table-header'}>Investment Type</p>
                <p className={'investor-table-header'}>Status</p>
            </div>
            <div className={'investor-detail'}>
                {investors.map((investor, index) => {
                    return(
                        <div key={index}>
                            <div className={`administrator-investor-row admin-investor-row 
                            ${investor.isPending ? 'investor-table-detail-row-pending' : 'investor-table-detail-row'}`}
                                 onClick={() => handleNavigateSinglePage(investor.id)}>
                                <p className={'investor-text'}>{ investor.investor }</p>
                                <p className={'investor-text'}>{ investor.industry }</p>
                                <p className={'investor-text'}>{ investor.investorType }</p>
                                <p className={'investor-text'}>{ investor.investmentType }</p>
                                <p className={'investor-text'}>{ investor.isPending ? 'Pending' : 'Approved' }</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AdminInvestorTable;