import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { message } from "antd";
import axios from 'axios';
import { useSelector } from 'react-redux';

import CustomTextInputInline from "../../components/common/textInput/CustomTextInputInline";
import ProfileSelectButton from "../../components/profile/ProfileSelectButton";
import CustomButton from "../../components/common/button/CustomButton";
import { DEV_URL } from '../../configuration/config';

import './profileScreen.css';
import {Dna} from "react-loader-spinner";

const ProfileScreen = () => {
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const [profile, setProfile] = useState({});
    const [isPasswordResetShown, setIsPasswordResetShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reEnterPassword, setReEnterPassword] = useState('');

    const [newPasswordError, setNewPasswordError] = useState(false);
    const [reEnterPasswordError, setReEnterPasswordError] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const fetchUser = async () => {
            if(user.id !== undefined){
                try{
                    const { data } = await axios.get(`${DEV_URL}/api/auth/info/${user.id}`);

                    setProfile({
                        id: data.id,
                        email: data.email,
                        companyName: data.companyName,
                        contactNumber: data.contactNumber,
                        displayName: data.displayName
                    });
                }catch (error){
                    console.log(error);
                }
            }
        }
        fetchUser();

        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    },[]);

    const passwordResetContainerAvailable = () => {
        setIsPasswordResetShown(true);
    }

    const passwordResetContainerNotAvailable = () => {
        setIsPasswordResetShown(false);
    }

    const passwordResetHandler = async () => {
        const passwordValid = newPassword.length > 5;
        const reEnterPasswordValid = reEnterPassword.length > 5;

        setNewPasswordError(false);
        setReEnterPasswordError(false);

        if(passwordValid && reEnterPasswordValid && newPassword === reEnterPassword){
            if(newPassword === reEnterPassword && newPassword.length > 0 && currentPassword.length > 0){
                try{
                    await axios.post(`${DEV_URL}/api/auth/password`, {
                        id: user.id,
                        oldPassword: currentPassword,
                        newPassword: newPassword
                    });
                    setIsPasswordResetShown(false);
                }catch (error){
                    if(error.response.data.message) {
                        message.error(error.response.data.message, 5);
                    }
                }
            }else {
                message.error('enter old password')
            }
        }else {
            setNewPasswordError(true);
            setReEnterPasswordError(true);
        }
    }

    const handleEditProfile = () => {
        if(user.id !== undefined){
            if(user?.type === 2){
                navigate(`/register/investor?user_id=${user.id}`);
            }else if(user?.type === 1){
                navigate(`/register/company?user_id=${user.id}`)
            }
        }
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'profile-screen'}>
            <div className={'profile-head-section'}>
                <img
                    className={'profile-image'}
                    src={ 'https://images.freeimages.com/images/previews/e30/forest-1402418.jpg' }
                    alt={ profile.displayName }
                />
                <p className={'profile-title'}>{ profile.displayName }</p>
            </div>
            {(user.type === 2 || user.type === 1) && <div className={'profile-screen-action-button-container'}>
                <CustomButton buttonClick={handleEditProfile} title={'Edit Profile'} color={'#000000'}/>
            </div>}
            <div className={'profile-foot-section'}>
                <div className={'profile-row'}>
                    <p className={'profile-row-title'}>Company Name </p>
                    <p className={'profile-row-text'}>{ profile.companyName }</p>
                </div>
                <div className={'profile-row'}>
                    <p className={'profile-row-title'}>Email </p>
                    <p className={'profile-row-text'}>{ profile.email }</p>
                </div>
                <div className={'profile-row'}>
                    <p className={'profile-row-title'}>Contact Number </p>
                    <p className={'profile-row-text'}>{ profile.contactNumber }</p>
                </div>
                <div className={'profile-row'}>
                    <p className={'profile-row-title'}>Password </p>
                    <p className={'profile-link'} onClick={ passwordResetContainerAvailable }>Reset Password</p>
                </div>
                {isPasswordResetShown && <div className={'profile-password-reset-container'}>
                    <CustomTextInputInline inputValue={currentPassword} placeholder={'Enter Old Password'} isPassword={true}
                                           setValue={(e) => setCurrentPassword(e.target.value)} title={'Old Password'}
                                           id={'old-password'} htmlFor={'old-password'} isPasswordReset={true}/>
                    <CustomTextInputInline inputValue={newPassword} placeholder={'Enter New Password'} isPassword={true}
                                           setValue={(e) => setNewPassword(e.target.value)} title={'New Password'}
                                           isErrorMessage={newPasswordError}
                                           errorMessage={'Enter a valid password (Password must contain at least 6 characters)'}
                                           id={'new-password'} htmlFor={'new-password'} isPasswordReset={true}/>
                    <CustomTextInputInline inputValue={reEnterPassword} placeholder={'Confirm New Password'} isPassword={true}
                                           setValue={(e) => setReEnterPassword(e.target.value)} title={'Confirm Password'}
                                           id={'confirm-password'} htmlFor={'confirm-password'} isPasswordReset={true}
                                           isErrorMessage={reEnterPasswordError}
                                           errorMessage={'Enter a valid password (Password must contain at least 6 characters)'}/>
                    <div className={'profile-button-container'}>
                        <ProfileSelectButton title={'Cancel'} clickHandler={ passwordResetContainerNotAvailable }/>
                        <ProfileSelectButton title={'Reset'} clickHandler={ passwordResetHandler }/>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default ProfileScreen;