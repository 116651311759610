import React from "react";
import { Button } from "antd";

import './SelectOptionButton.css';

const SelectOptionButton = ({ title, clickHandler, selected, isSmall = false }) => {
    return(
        <div>
            <Button onClick={ clickHandler }
                    className={ `${selected ? 'select-option-button-select' : 'select-option-button'}
                     ${isSmall && 'select-option-button-small'}`}>{ title }</Button>
        </div>
    )
}

export default SelectOptionButton;