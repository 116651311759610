export const LOG_IN_USER = 'LOG_IN_USER';
export const CHANGE_STATUS_AS_REGISTERED = 'CHANGE_STATUS_AS_REGISTERED';

export const loginUser = (user, token) => {
    return {
        type: LOG_IN_USER,
        payload: {
            user,
            token
        }
    }
}

export const changeStateAsRegistered = () => {
    let userLocal = localStorage.getItem('user');
    let userObj = JSON.parse(userLocal);
    userObj.status = 'Registered';
    
    localStorage.removeItem('user');

    localStorage.setItem('user', JSON.stringify(userObj));

    return{
        type: CHANGE_STATUS_AS_REGISTERED,
        payload: {
            
        }
    }
}