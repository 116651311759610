import React from "react";
import { Input } from "antd";

import SelectOptionButton from "../common/button/SelectOptionButton";
import CustomTextInputInline from "../common/textInput/CustomTextInputInline";

import './CompanyRegisterForm.css';

const { TextArea } = Input;

const CompanyRegisterFormPart3 = ({ onChange, formData, isFieldError }) => {

    const handleProductMaturity = (type) => {
        onChange('productMaturity', type);
    }

    const handleProductDescription = (e) => {
        onChange('productDescription', e.target.value);
    }

    const handleProductType = (type) => {
        onChange('productType', type);
    }

    const handleTechnologyEmployed = (type) => {
        onChange('technologyEmployed', type)
    }

    const handleTechnologyEmployedDescription = (e) => {
        onChange('technologyEmployedDescription', e.target.value);
    }

    return(
        <div className={'register-form'}>
            <div className={'register-button-group-container2-custom'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'productMaturity'} className="cus-label" style={{textAlign: 'left'}}>
                        Product Maturity
                    </label>
                </div>
                <div className={'register-button-group2-extra'}>
                    <SelectOptionButton title={'Developing'} selected={formData.productMaturity === 'Developing'}
                                        clickHandler={() => handleProductMaturity('Developing')}/>
                    <SelectOptionButton title={'Deployed'} selected={formData.productMaturity === 'Deployed'}
                                        clickHandler={() => handleProductMaturity('Deployed')}/>
                </div>
            </div>

            <div className={'register-text-area-container'}>
                <label htmlFor={'productDescription'} className="cus-label">
                    Company Product Description
                </label>
                <div className={'register-text-area-input-container'}>
                    <TextArea rows={4} value={formData.productDescription}
                              className={'register-text-area'}
                              placeholder={'50 words'}
                              onChange={handleProductDescription}/>
                    {isFieldError.isProductDescriptionError &&
                        <p className="error-message">Enter valid Product Description</p>}
                </div>
            </div>

            <div className={'register-button-group-container2-custom'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'productType'} className="cus-label" style={{textAlign: 'left'}}>
                        Product Ownership
                    </label>
                </div>
                <div className={'register-button-group2-extra'}>
                    <SelectOptionButton title={'Proprietary'} selected={formData.productType === 'Proprietary'}
                                        clickHandler={() => handleProductType('Proprietary')}/>
                    <SelectOptionButton title={'Co-Developed'} selected={formData.productType === 'Co-Developed'}
                                        clickHandler={() => handleProductType('Co-Developed')}/>
                </div>
            </div>
            <div className={'register-button-group-container2'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'technologyEmployed'} className="cus-label" style={{textAlign: 'left'}}>
                        Core Technology
                    </label>
                </div>
                <div className={'register-button-group2-extra-2'}>
                    <SelectOptionButton title={'AI'} selected={formData.technologyEmployed === 'AI'}
                                        clickHandler={() => handleTechnologyEmployed('AI')}/>
                    <SelectOptionButton title={'IOT'} selected={formData.technologyEmployed === 'IOT'}
                                        clickHandler={() => handleTechnologyEmployed('IOT')}/>
                    <SelectOptionButton title={'Blockchain'} selected={formData.technologyEmployed === 'Blockchain'}
                                        clickHandler={() => handleTechnologyEmployed('Blockchain')}/>
                    <SelectOptionButton title={'Security'} selected={formData.technologyEmployed === 'Security'}
                                        clickHandler={() => handleTechnologyEmployed('Security')}/>
                    <SelectOptionButton title={'Hardware'} selected={formData.technologyEmployed === 'Hardware'}
                                        clickHandler={() => handleTechnologyEmployed('Hardware')}/>
                    <SelectOptionButton title={'Other'} selected={formData.technologyEmployed === 'Other'}
                                        clickHandler={() => handleTechnologyEmployed('Other')}/>
                </div>
            </div>

            <div className={'register-button-and-field-container'}>
                <div></div>
                <CustomTextInputInline large={true} isOther={true} title={'technologyEmployedDescription'}
                                       isNormal={true} placeholder={'Please mention if other'}
                                       inputValue={formData.technologyEmployedDescription}
                                       setValue={handleTechnologyEmployedDescription}
                                       id={'technologyEmployedDescription'}
                                       htmlFor={'technologyEmployedDescription'}
                                       isDisable={formData.technologyEmployed !== 'Other'}/>
            </div>
        </div>
    )
}

export default CompanyRegisterFormPart3;