import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Image } from "antd";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Dna } from 'react-loader-spinner';

import InvestorSelectButton from "../../components/investor/InvestorSelectButton";
import { DEV_URL } from '../../configuration/config';

import './SingleInvestorDetailScreen.css';

import logo from "../../assets/common/logo-new-1.png";

const SingleInvestorDetailScreen = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(true);
    const user = useSelector(state => state.auth.user);
    const [investor, setInvestor] = useState({ });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if(user.type === 3){
            setIsAdmin(true);
        }else{
            setIsAdmin(false);
        }

        const fetchSingleInvestorDetails = async () => {
            const { data } = await axios.get(`${DEV_URL}/api/investors/${id}`)
            setInvestor({
                name: `${data.firstName} ${data.lastName}`,
                isPending: data.status === 'Pending',
                country: data.country,
                address: data.address,
                investmentAmount: data.investmentAmount,
                investorType: data.investorType,
                industry1: data.investorIndustry1,
                industry2: data.investorIndustry2,
                industry3: data.investorIndustry3,
                industry4: data.investorIndustry4,
                InvestmentTypes: data.investmentTypes,
                linkedInProfile: data.linkedInProfile,
                website: data.website
            });
        }

        fetchSingleInvestorDetails();

        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    },[user]);

    const goBackHandler = () => {
        navigate('/investor');
    }

    const approveRequest = async () => {
        try{
            await axios.get(`${DEV_URL}/api/investors/approve/${id}`);
        }catch (error){
            console.log(error);
        }
        goBackHandler();
    }

    const declineRequest = async () => {
        try{
            await axios.get(`${DEV_URL}/api/investors/reject/${id}`);
        }catch (error){
            console.log(error);
        }
        goBackHandler();
    }

    const adminAndNotApproved = () => {
        return isAdmin && investor.isPending;
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'single-investor-page'}>
            <div className={'single-investor-header-container'}>
                <div className={'single-investor-name-container'}>
                    <p className={'single-investor-name'}>{investor.name}</p>
                    <p className={'back-button'} onClick={goBackHandler}>Back</p>
                </div>
                <div className={'investor-image-container'}>
                    <Image src={logo} alt={'logo'} preview={false} className={'investor-image'}/>
                </div>
            </div>
            {adminAndNotApproved() && <div className={'single-investor-action-row'}>
                <div className={'single-investor-button'} onClick={approveRequest}>
                    <InvestorSelectButton title={'Approve'} isApprove={true}/>
                </div>
                <div className={'single-investor-button'} onClick={declineRequest}>
                    <InvestorSelectButton title={'Delete'} isApprove={false}/>
                </div>
                <div></div>
            </div>}
            <div className={'single-investor-detail'}>
                {investor.name && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Investor name :</p>
                    <p className={'investor-detail-des'}>{ investor.name }</p>
                </div>}
                {/*investor.country && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Country :</p>
                    <p className={'investor-detail-des'}>{ investor.country }</p>
                </div>*/}
                {investor.address && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Address :</p>
                    <p className={'investor-detail-des'}>{ investor.address }</p>
                </div>}
                {investor.investmentAmount && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Assets Under Management (USD) :</p>
                    <p className={'investor-detail-des'}>{ investor.investmentAmount }</p>
                </div>}
                {investor.linkedInProfile && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>LinkedIn Profile :</p>
                    <p className={'investor-detail-des'}>
                        <a href={investor.linkedInProfile} target="_blank" rel="noopener noreferrer">
                            {investor.linkedInProfile}
                        </a>
                    </p>
                </div>}
                {investor.website && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Web Site :</p>
                    <p className={'investor-detail-des'}>
                        <a href={investor.website} target="_blank" rel="noopener noreferrer">
                            {investor.website}
                        </a>
                    </p>
                </div>}
                {investor.investorType && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Investor Type :</p>
                    <p className={'investor-detail-des'}>{ investor.investorType }</p>
                </div>}
                {investor.industry1 && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Primary Industry :</p>
                    <p className={'investor-detail-des'}>{ investor.industry1 }</p>
                </div>}
                {investor.industry2 && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Industry (Optional 1) :</p>
                    <p className={'investor-detail-des'}>{ investor.industry2 }</p>
                </div>}
                {investor.industry3 && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Industry (Optional 2) :</p>
                    <p className={'investor-detail-des'}>{ investor.industry3 }</p>
                </div>}
                {investor.industry4 && <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Industry (Optional 3) :</p>
                    <p className={'investor-detail-des'}>{ investor.industry4 }</p>
                </div>}
                <div className={'investor-detail-row'}>
                    <p className={'investor-detail-title'}>Investment Type :</p>
                    <p className={'investor-detail-des'}>{investor.InvestmentTypes && investor.InvestmentTypes.map((type, index) => {
                        return(
                                <div key={index} className="single-investor-type">
                                    <span>{ type }</span>
                                </div>
                            )
                        }) }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SingleInvestorDetailScreen;