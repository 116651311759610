import React from "react";
import { useNavigate } from 'react-router-dom';

import { Image } from "antd";

import CustomButton from "../common/button/CustomButton";

import tired from '../../assets/dashboard/tired-new.png';

import './NotRegisterYet.css';

const NotRegisteredYet = ({ accountType }) => {
    const navigate = useNavigate();

    const navigationHandler = () => {
        if(accountType === 1){
            navigate('/register/company');
        }else{
            navigate('/register/investor');
        }
    }

    return(
        <div className={'not-register-yet-container'}>
            <div className={'not-register-yet-image-container'}>
                <Image src={tired} preview={false} className={'not-register-yet-image'}/>
            </div>
            <div className={'not-register-yet-text-container'}>
                <p className={'not-register-yet-text'}>It seems like you haven’t submitted your application.</p>
                <p className={'not-register-yet-text'}>Apply now to view/ schedule meetings</p>
            </div>
            <CustomButton color={'#000000'} title={'Apply Now'} buttonClick={navigationHandler} isRegisterButton={true}/>
        </div>
    )
}

export default NotRegisteredYet;