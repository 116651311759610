import React from 'react';
import { Tooltip } from 'antd';
import { FaPuzzlePiece } from 'react-icons/fa';

import './OverallMatches.css';

const OverallMatches = ({ type, matches }) => {
    return(
        <div className={`overall-match-container ${type === 'Investor' ? 'overall-investor-container' :
             'overall-company-container'}`}>
            <div className='overall-match-header'>
                <FaPuzzlePiece color="white" size={32} />
                <p className='overall-match-title'>Overall {type === 'Investor' ? 'Company' : 'Investor' } Matches</p>
            </div>
            <div className='overall-match-list-container'>
                {matches.map((match,index) => {
                    return(
                        <div key={index} className='overall-match-item'>
                            <p className='overall-match-item-text'>#{ index + 1 }</p>
                            <p className='overall-match-item-text'>{ match.name }</p>
                            <Tooltip title="Primary Industry" color={ type === 'Investor' ? '#87ceeb' : '#c3b1e1' } placement="top">
                                <p className='overall-match-item-text overall-match-item-special'>{ match.industry }</p>
                            </Tooltip>
                        </div>
                    )   
                })}
            </div>
        </div>
    )
}

export default OverallMatches;