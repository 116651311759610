import React from 'react';
import { DeleteOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import './ScheduleTable.css';

const AdminScheduleTable = ({ events, onDeleteClick, declineRequest, acceptRequest }) => {

    const selectedEvents = events.filter((eve) => {
        return eve;
    });

    const dynamicClassNameToState = (status) => {
        if(status === 'Pending'){
            return 'meeting-pending';
        }else if(status === 'Accepted'){
            return 'meeting-accepted';
        }else if(status === 'Booked'){
            return 'meeting-booked';
        }else{
            return 'meeting-rejected';
        }
    }

    return(
        <div className={'admin-events-table'}>
            <div className={'admin-events-row events-header'}>
                <p className={'events-table-header'}>Date</p>
                <p className={'events-table-header'}>Time</p>
                <p className={'events-table-header'}>Mode</p>
                {/*<p className={'events-table-header'}>Location</p>*/}
                <p className={'events-table-header'}>Company</p>
                <p className={'events-table-header'}>Investor</p>
                <p className={'events-table-header'}>Action</p>
            </div>
            <div className={'events-detail'}>
                {selectedEvents.map((eve, index) => {
                    return(
                        <div className={`admin-events-row ${dynamicClassNameToState(eve.status)}`} key={index}>
                            <p className={'event-text'}>{eve.day}</p>
                            <p className={'event-text'}>{eve.startingTime} - {eve.endingTime}</p>
                            <p className={'event-text'}>{eve.room}</p>
                            {/*<p className={'event-text'}>{ eve.roomType }</p>*/}
                            <p className={'event-text'}>{eve.company}</p>
                            <p className={'event-text'}>{eve.investor}</p>
                            {<div className={'events-action'}>
                                <DeleteOutlined onClick={() => onDeleteClick(eve)} className={'event-delete-icon'}/>
                                {eve.status === 'Pending' && <CheckOutlined className={'events-action-icon'}
                                                                            onClick={() => acceptRequest(eve.id)}/>}
                                {eve.status === 'Pending' && <CloseOutlined className={'events-action-icon'}
                                                                            onClick={() => declineRequest(eve.id)}/>}
                            </div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AdminScheduleTable;