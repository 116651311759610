import React from 'react';
import { Modal } from 'antd';

import MeetingTypeSelectionButton from "./MeetingTypeSelectionButton";

import './InvestorAccpetMeetingModel.css';

const InvestorAcceptMeetingModel = ({ isOpen, clickOk, clickCancel, acceptMeetingType, acceptMeetingLocation,
                                        changeAcceptMeetingType, changeAcceptMeetingLocation, note, changeNote }) => {
    return(
        <Modal title="MEETING REQUESTS" open={isOpen} onOk={clickOk} onCancel={clickCancel} className={'custom-model'}
               okButtonProps={{className: 'custom-model-ok-button'}}>
            <div className={'investor-meeting-accept-container'}>
                <p className={'investor-meeting-accept-text'}>Will you be able to attend this meeting physically or virtually?</p>
                <div className={'investor-meeting-accept-button-container'}>
                    <MeetingTypeSelectionButton title={'Physical'} selected={acceptMeetingType === 'Physical'}
                                                clickHandler={() => changeAcceptMeetingType('Physical')}/>
                    <MeetingTypeSelectionButton title={'Virtual'} selected={acceptMeetingType === 'Virtual'}
                                                clickHandler={() => changeAcceptMeetingType('Virtual')}/>
                </div>
                {/*acceptMeetingType === 'Physical' && <>
                    <p className={'investor-meeting-accept-text'}>Will you be able to attend this meeting On Event or Custom Location?</p>
                    <div className={'investor-meeting-accept-button-container'}>
                        <MeetingTypeSelectionButton title={'On Event'} selected={acceptMeetingLocation === 'Event'}
                                                    clickHandler={() => changeAcceptMeetingLocation('Event')}/>
                        <MeetingTypeSelectionButton title={'Outside'} selected={acceptMeetingLocation === 'Outside'}
                                                    clickHandler={() => changeAcceptMeetingLocation('Outside')}/>
                    </div>
                </>*/}
                <div>
                    <textarea value={note} onChange={changeNote} placeholder={'* Note to Company '}
                              className={'new-schedule-note'}>
                    </textarea>
                </div>
            </div>
        </Modal>
    )
}

export default InvestorAcceptMeetingModel;