import React, { useState, useEffect } from "react";
import {Steps, Image, message} from 'antd';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dna } from "react-loader-spinner";

import InvestorRegisterFormPart2 from "../../components/register/InvestorRegisterFormPart2";
import CustomButton from "../../components/common/button/CustomButton";
import CustomAlert from "../../components/common/alert/CustomAlert";
import InvestorRegisterFormPart1 from "../../components/register/InvestorRegisterFormPart1";
import InvestorRegisterFormPart3 from "../../components/register/InvestorRegisterFormPart3";
import { changeStateAsRegistered } from '../../store/actions/LoginAction';

import { DEV_URL } from '../../configuration/config';

import './RegisterAsScreen.css';

import logo from '../../assets/common/logo-new-1.png';

const { Step } = Steps;

const steps = [
    {
        title: 'General Info',
        component: InvestorRegisterFormPart1,
    },
    {
        title: 'Investment Info',
        component: InvestorRegisterFormPart3,
    },
    {
        title: 'Investor Info',
        component: InvestorRegisterFormPart2,
    }
];

const RegisterAsInvestor = () => {
    const [searchParams] = useSearchParams();
    const user_id = searchParams.get('user_id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const [currentStep, setCurrentStep] = useState(0);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [investorId, setInvestorId] = useState(0);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        country: '',
        address: '',
        linkedInProfile: '',
        website: '',

        investorType: 'Angel-Investor', //need to merge with bellow
        investorTypeDescription: '',
        investmentAmount: 0,

        investmentType: [], // need to merge with bellow
        investmentTypeDescription: '',
        investmentIndustryPreference1: '',
        investmentIndustryPreference2: '',
        investmentIndustryPreference3: '',
        investmentIndustryPreference4: ''
    });

    const [isFieldError, setIsFieldError] = useState({
        isFirstNameError: false,
        isLastNameError: false,
        isCountryError: false,
    });

    useEffect(() => {
        fetchInvestorData();
    }, [user_id])

    const fetchInvestorData = async () => {
        if (user_id !== null) {
            try {
                const { data } = await axios.get(`${DEV_URL}/api/investors/user/${user_id}`);
                const investorId = data?.id;
                setInvestorId(investorId);

                const { data: investorData } = await axios.get(`${DEV_URL}/api/investors/${investorId}`);

                setFormData({
                    firstName: investorData?.firstName,
                    lastName: investorData?.lastName,
                    country: investorData?.country,
                    address: investorData?.address,
                    linkedInProfile: investorData?.linkedInProfile,
                    website: investorData?.website,
                    investorType: investorData?.investorType,
                    investorTypeDescription: '',
                    investmentAmount: investorData?.investmentAmount,
                    investmentType: investorData?.investmentTypes,
                    investmentTypeDescription: '',
                    investmentIndustryPreference1: investorData?.investorIndustry1,
                    investmentIndustryPreference2: investorData?.investorIndustry2,
                    investmentIndustryPreference3: investorData?.investorIndustry3,
                    investmentIndustryPreference4: investorData?.investorIndustry4
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleNext = () => {
        setIsError(false);
        if(currentStep === 0){
            const firstNameValid = formData.firstName.trim().length >= 3;
            const lastNameValid = formData.lastName.trim().length >= 3;
            const countryValid = formData.country.trim().length >= 1;

            setIsFieldError({
                isFirstNameError: !firstNameValid,
                isLastNameError: !lastNameValid,
                isCountryError: !countryValid
            });

            if(firstNameValid && lastNameValid && countryValid){
                setCurrentStep(currentStep + 1);
            }else {
                setIsError(true);
            }
        }else if(currentStep === 1){
            setIsFieldError({
                isFirstNameError: false,
                isLastNameError: false,
                isCountryError: false
            });

            setIsError(false);
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    }

    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsError(false);

        if(formData.investmentAmount === ''){
            formData.investmentAmount = 0;
        }else {
            formData.investmentAmount = parseInt(formData.investmentAmount);
        }

        const finalInvestmentTypeList = [...formData.investmentType];
        if(finalInvestmentTypeList.includes('Other')){
            finalInvestmentTypeList.push(formData.investmentTypeDescription);
        }
        const finalInvestorTypeList = formData.investorType !== 'Other' ? formData.investorType :
            formData.investorTypeDescription;
        const userId = user.id;

        if(user_id === null){
            try {
                const response = await axios.post(`${DEV_URL}/api/register/investor`,{
                    userId: userId,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    country: formData.country,
                    address: formData.address,
                    investorType: finalInvestorTypeList,
                    investmentAmount: formData.investmentAmount,
                    investmentType: finalInvestmentTypeList,
                    investmentIndustryPreference1: formData.investmentIndustryPreference1,
                    investmentIndustryPreference2: formData.investmentIndustryPreference2,
                    investmentIndustryPreference3: formData.investmentIndustryPreference3,
                    investmentIndustryPreference4: formData.investmentIndustryPreference4,
                    linkedInProfile: formData.linkedInProfile,
                    website: formData.website
                });

                dispatch(changeStateAsRegistered());

                message.success('Successfully submitted !');
                navigate('/');
            }catch (err){
                console.log(err.message);
                message.error('Registration error!');
            }
        }else{
            try{
                const response = await axios.post(`${DEV_URL}/api/register/investor/update`,{
                    investorId,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    country: formData.country,
                    address: formData.address,
                    investorType: finalInvestorTypeList,
                    investmentAmount: formData.investmentAmount,
                    investmentType: finalInvestmentTypeList,
                    investmentIndustryPreference1: formData.investmentIndustryPreference1,
                    investmentIndustryPreference2: formData.investmentIndustryPreference2,
                    investmentIndustryPreference3: formData.investmentIndustryPreference3,
                    investmentIndustryPreference4: formData.investmentIndustryPreference4,
                    linkedInProfile: formData.linkedInProfile,
                    website: formData.website
                });

                message.success('Successfully submitted !');
                navigate('/profile');
            }catch (error){
                console.log(error.message);
                message.error('Investor update error!');
            }
        }
        setIsLoading(false);
    };

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    const CurrentStepComponent = steps[currentStep].component;

    return(
        <div className={'register-as-page'}>
            <div className={'register-image-container'}>
                <Image src={ logo } preview={false} width={160} height={180} className={'register-image'}/>
            </div>
            <div className={'register-info-container'}>
                <div className={'register-page-title-container'}>
                    <p className={'register-page-title'}>Investor Registration</p>
                </div>
                <div className={'register-page-description-container'}>
                    <p className={'register-page-description-text'}>Please Fill In the Necessary Information in the Relevant Fields</p>
                </div>
                {isError && <CustomAlert description={'form input error please recheck and enter inputs'}/>}
                <div className={'register-field-box'}>
                    {<CurrentStepComponent onChange={handleChange} formData={formData} isFieldError={isFieldError}/>}
                </div>
                <div className={'register-form-footer'}>
                    <div className={'register-step-container'}>
                        <div className={'register-step'}>
                            <Steps progressDot current={currentStep} className="custom-steps">
                                {steps.map((step) => (
                                    <Step key={step.title} title={step.title} style={{color: 'white'}}/>
                                ))}
                            </Steps>
                        </div>
                    </div>
                    <div className={'register-step-button'}>
                        <div className={'register-button-container'}>
                            { currentStep !== 0 && <CustomButton title={'Previous'} className={'register-button'}
                                                                 buttonClick={ handlePrevious } color={'#6473b7'}/>  }
                        </div>
                        {currentStep < steps.length - 1 ? (
                            <CustomButton title={'Next'} buttonClick={ handleNext } className={'register-button'} color={'#6473b7'}/>
                        ) : (
                            <CustomButton title={'Submit'} buttonClick={handleSubmit} className={'register-button'} color={'#6473b7'}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterAsInvestor;