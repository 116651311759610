import React from "react";
import { Select, Input, Tooltip } from "antd";

import CustomTextInputInline from "../common/textInput/CustomTextInputInline";

import './CompanyRegisterForm.css';

const { Option } = Select;
const { TextArea } = Input;

const countryList = [ "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Brazzaville)",
    "Congo (Kinshasa)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territories",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];

const InvestorRegisterFormPart1 = ({ onChange, formData, isFieldError }) => {

    const firstNameHandler = (e) => {
        onChange('firstName', e.target.value);
    }

    const lastNameHandler = (e) => {
        onChange('lastName', e.target.value);
    }

    const linkedInProfileHandler = (e) => {
        onChange('linkedInProfile', e.target.value);
    }

    const websiteHandler = (e) => {
        onChange('website', e.target.value);
    }

    const countryHandler = (type) => {
        onChange('country', type);
    }

    const addressHandler = (e) => {
        onChange('address', e.target.value);
    }

    /*const roleInCompanyHandler = (e) => {
        onChange('companyRole', e.target.value);
    }*/

    return(
        <div className={'register-form'}>
            <CustomTextInputInline  large={true} title={'First Name'} isNormal={true}  placeholder={'Enter first name'}
                                    inputValue={formData.firstName} setValue={firstNameHandler}
                                    id={'firstName'} htmlFor={'firstName'} isErrorMessage={isFieldError.isFirstNameError}
                                    errorMessage={'Enter valid First Name'}/>
            <CustomTextInputInline  large={true} title={'Last Name'} isNormal={true}  placeholder={'Enter last name'}
                                    inputValue={formData.lastName} setValue={lastNameHandler}
                                    id={'lastName'} htmlFor={'lastName'} isErrorMessage={isFieldError.isLastNameError}
                                    errorMessage={'Enter valid Last Name'}/>
            <div className={'register-select-container'}>
                <label htmlFor={'country'} className="cus-label">
                    Country
                </label>
                <div className={'register-select-input-container'}>
                    <Tooltip title="Country" color='#264294' placement="bottom">
                        <Select className={'register-select'} value={formData.country} onChange={countryHandler} 
                        size={'large'} placeholder={'Country'}>
                            { countryList.map(country => {
                                return <Option value={country} key={country}>{country}</Option>
                            })}
                        </Select>
                    </Tooltip>
                    { isFieldError.isCountryError && <p className="error-message">Select a Country from the list</p> }
                </div>
            </div>
            <div className={'register-text-area-container'}>
                <label htmlFor={'address'} className="cus-label">
                    Address
                </label>
                <div className={'register-text-area-input-container'}>
                    <TextArea className={'register-text-area'} rows={3} value={formData.address}
                              placeholder={'Enter Address'}
                              onChange={addressHandler} />
                </div>
            </div>
            <CustomTextInputInline  large={true} title={'LinkedIn Profile'} isNormal={true}  placeholder={'Enter linkedin profile'}
                                    inputValue={formData.linkedInProfile} setValue={linkedInProfileHandler}
                                    id={'linkedInProfile'} htmlFor={'linkedInProfile'} isErrorMessage={false}
                                    errorMessage={'Enter valid Linkedin profile'}/>
            <CustomTextInputInline  large={true} title={'Web Site'} isNormal={true}  placeholder={'Enter Website'}
                                    inputValue={formData.website} setValue={websiteHandler} id={'website'} htmlFor={'website'}
                                    isErrorMessage={false} errorMessage={'Enter valid web site'}/>
            {/*<CustomTextInputInline  large={true} title={'Role in Company'} isNormal={true}  placeholder={'Enter role in company'}
                                    inputValue={formData.companyRole} setValue={roleInCompanyHandler}
                                    id={'companyRole'} htmlFor={'companyRole'}/>*/}
        </div>
    )
}

export default InvestorRegisterFormPart1;