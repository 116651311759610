import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Image } from "antd";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Dna } from 'react-loader-spinner';

import './SingleCompanyDetailScreen.css';
import { DEV_URL } from '../../configuration/config';

import logo from '../../assets/common/logo-new-1.png';
import CompanySelectButton from "../../components/company/CompanySelectButton";

import Goal1 from '../../assets/lms/E-WEB-Goal-10.png';
import Goal2 from '../../assets/lms/E-WEB-Goal-02.png';
import Goal3 from '../../assets/lms/E-WEB-Goal-03.png';
import Goal4 from '../../assets/lms/E-WEB-Goal-04.png';
import Goal5 from '../../assets/lms/E-WEB-Goal-05.png';
import Goal6 from '../../assets/lms/E-WEB-Goal-06.png';
import Goal7 from '../../assets/lms/E-WEB-Goal-07.png';
import Goal8 from '../../assets/lms/E-WEB-Goal-08.png';
import Goal9 from '../../assets/lms/E-WEB-Goal-09.png';
import Goal10 from '../../assets/lms/E-WEB-Goal-10.png';
import Goal11 from '../../assets/lms/E-WEB-Goal-11.png';
import Goal12 from '../../assets/lms/E-WEB-Goal-12.png';
import Goal13 from '../../assets/lms/E-WEB-Goal-13.png';
import Goal14 from '../../assets/lms/E-WEB-Goal-14.png';
import Goal15 from '../../assets/lms/E-WEB-Goal-15.png';
import Goal16 from '../../assets/lms/E-WEB-Goal-16.png';
import Goal17 from '../../assets/lms/E-WEB-Goal-17.png';

const SingleCompanyDetailScreen = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const routeType = searchParams.get('type');

    const [isAdmin, setIsAdmin] = useState(true);
    const user = useSelector(state => state.auth.user);
    const [company, setCompany] = useState({isPending: true});
    const [isLoading, setIsLoading] = useState(false);
    const [companyLlmData, setCompanyLlmData] = useState({
        companyName: '',
        potential: '',
        scalability: '',
        challenges: "",
        un_sdg: []
    });
    const [videoWidth, setVideoWidth] = useState(560);

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth > 600){
                setVideoWidth(560);
            }else {
                setVideoWidth(window.innerWidth - 60);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const fetchCompanies = async () => {
            const { data } = await axios.get(`${DEV_URL}/api/companies/${id}`);
            const company = data.company;

            if(user.type === 3){
                setIsAdmin(true);
            }else if(user.type === 2){
                setIsAdmin(false);
            }else {
                setIsAdmin(true);
            }

            setCompany({
                name: company.companyName,
                isPending: company.status === 'Pending',
                businessStages: company.businessStages,
                partnershipRequirement: company.partnershipRequirement,
                companyType: company.companyType,
                numberOfEmployees: company.numberOfEmployees,
                elevatorPitch: company.elevatorPitch,
                isSustainable: company.sustainability,
                productDescription: company.companyProducts,
                technologyEmployed: company.coreTechnology,
                productMaturity: company.productMaturity,
                industry1: company.companyIndustry1,
                industry2: company.companyIndustry2,
                industry3: company.companyIndustry3,
                industry4: company.companyIndustry4,
                keyValueProposition: company.keyValueProposition,
                marketPrecences: company.marketPresences,
                collaterals: company.collaterals,
                stakeholders: company.stakeholders,
                productOwnership: company.productOwnership,
                videoLink: company.videoLink,
            });
        }

        const fetchLlmData = async () => {
            try{
                if(user.type === 2){
                    // when fixed llm remove commented
                    const { data } = await axios.post(`${DEV_URL}/api/llm`, {
                        userId: user.id,
                        companyId: id
                    });

                    console.log(data);
                    setCompanyLlmData({
                        companyName: data.company_name,
                        un_sdg: data.un_sdg,
                        potential: data.potential,
                        scalability: data.scalability,
                        challenges: data.challenges
                    });
                }
            }catch (error){
                console.log(error);
            }
        }

        fetchCompanies();
        fetchLlmData();

        setTimeout(() => {
            setIsLoading(false);
        }, 8000);
        Promise.all([fetchCompanies(), fetchLlmData()])
            .then(() => {
                setIsLoading(false);
            });
    },[user]);

    const imagesToShow = () => {
        const goalImages = {
            1: Goal1, 2: Goal2, 3: Goal3, 4: Goal4, 5: Goal5, 6: Goal6, 7: Goal7, 8: Goal8, 9: Goal9,
            10: Goal10, 11: Goal11, 12: Goal12, 13: Goal13, 14: Goal14, 15: Goal15, 16: Goal16, 17: Goal17,
        };
        const selectedImages = companyLlmData.un_sdg.map((sdgNumber) => goalImages[sdgNumber]);
        return selectedImages;
    }

    const goBackHandler = () => {
        if(routeType === 'Request'){
            navigate('/schedule');
        }else{
            navigate('/company');
        }
    }

    const approveRequest = async () => {
        try{
            await axios.get(`${DEV_URL}/api/companies/approve/${id}`);
        }catch (error){
            console.log(error);
        }
        navigate('/company');
    }

    const declineRequest = async () => {
        try{
            await axios.get(`${DEV_URL}/api/companies/reject/${id}`);
        }catch (error){
            console.log(error);
        }
        navigate('/company');
    }

    const deleteCompany = async () => {
        try{
            await axios.get(`${DEV_URL}/api/companies/reject/${id}`);
        }catch (error) {
            console.log(error);
        }
        navigate('/company');
    }

    const extractVideoId = (url) => {
        const videoIdRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
        const match = url.match(videoIdRegex);
        return match ? match[1] : null;
    };

    const adminAndNotApproved = () => {
        return isAdmin && company.isPending
    }

    const adminAndApproved = () => {
        return isAdmin && !company.isPending
    }

    if(isLoading){
        return (
            <div className={'content-container'}>
                <Dna height={140} width={140}/>
            </div>
        )
    }

    return(
        <div className={'single-company-page'}>
            <div className={'single-company-header-container'}>
                <div className={'single-company-name-container'}>
                    <p className={'single-company-name'}>{ company.name }</p>
                    <p className={'back-button'} onClick={goBackHandler}>Back</p>
                </div>
                <div className={'company-image-container'}>
                    <Image src={logo} alt={'logo'} preview={false} className={'company-image'}/>
                </div>
            </div>
            {adminAndNotApproved() && <div className={'single-company-action-row'}>
                <div className={'single-company-button'} onClick={approveRequest}>
                    <CompanySelectButton title={'Approve'} isApprove={true}/>
                </div>
                <div className={'single-company-button'} onClick={declineRequest}>
                    <CompanySelectButton title={'Delete'} isApprove={false}/>
                </div>
                <div></div>
            </div>}
            {adminAndApproved() && <div>
                <div className={'single-company-button'} onClick={deleteCompany}>
                    <CompanySelectButton title={'Remove Company'} isApprove={true}/>
                </div>
            </div>}
            {!isAdmin && <> <div className={'single-company-llm-container'}>
                <div className={'single-company-llm-left-side'}>
                    <div className={'single-company-llm-info-container'}>
                        <p className={'single-company-llm-info-title'}>Potential</p>
                        <p className={'single-company-llm-info-desc'}>{ companyLlmData.potential }</p>
                    </div>
                    <div className={'single-company-llm-info-container'}>
                        <p className={'single-company-llm-info-title'}>Scalability</p>
                        <p className={'single-company-llm-info-desc'}>{ companyLlmData.scalability }</p>
                    </div>
                    <div className={'single-company-llm-info-container'}>
                        <p className={'single-company-llm-info-title'}>Challenges</p>
                        <p className={'single-company-llm-info-desc'}>{ companyLlmData.challenges }</p>
                    </div>
                </div>
                <div className={'single-company-llm-right-side'}>
                    <p className={'single-company-llm-info-title'}>Alignment with UNSDGS</p>
                    <div className={'single-company-un-sdg-container'}>
                        { imagesToShow().map((sdg, index) => {
                            return(
                                <div key={index}>
                                    <img src={sdg} className={'single-company-un-sdg-image'}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={'single-company-header'}>
                <p className={'single-company-title'}>General Information</p>
            </div>
            <div className={'single-company-divider'}></div></>}
            <div className={'single-company-detail'}>
                {company.name && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Company Name :</p>
                    <p className={'company-detail-des'}>{ company.name }</p>
                </div>}
                {company.partnershipRequirement && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Partnership Requirement :</p>
                    <p className={'company-detail-des'}>{ company.partnershipRequirement }</p>
                </div>}
                {company.numberOfEmployees && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Number of Employees :</p>
                    <p className={'company-detail-des'}>{ company.numberOfEmployees }</p>
                </div>}
                {company.companyType && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Company Type :</p>
                    <p className={'company-detail-des'}>{ company.companyType }</p>
                </div>}
                {company.elevatorPitch && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Elevator Pitch :</p>
                    <p className={'company-detail-des'}>{ company.elevatorPitch }</p>
                </div>}
                {company.videoLink && <div className={'company-detail-video-row'}>
                    <p className={'company-detail-title'}>Elevator Pitch Video :</p>
                    <iframe width={videoWidth} height="315" src={`https://www.youtube.com/embed/${extractVideoId(company.videoLink)}`}
                        title="YouTube video" allowFullScreen frameBorder="0"></iframe>
                </div>}
                {company.isSustainable && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Sustainability :</p>
                    <p className={'company-detail-des'}>{ company.isSustainable }</p>
                </div>}
                {company.productDescription && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Product Description :</p>
                    <p className={'company-detail-des'}>{ company.productDescription }</p>
                </div>}
                {company.productType && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Product Ownership :</p>
                    <p className={'company-detail-des'}>{ company.productType }</p>
                </div>}
                {company.technologyEmployed && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Core Technology :</p>
                    <p className={'company-detail-des'}>{ company.technologyEmployed }</p>
                </div>}
                {company.industry1 && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Primary Industry :</p>
                    <p className={'company-detail-des'}>{ company.industry1 }</p>
                </div>}
                {company.industry2 && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Industry (Optional 1) :</p>
                    <p className={'company-detail-des'}>{ company.industry2 }</p>
                </div>}
                {company.industry3 && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Industry (Optional 2) :</p>
                    <p className={'company-detail-des'}>{ company.industry3 }</p>
                </div>}
                {company.industry4 && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Industry (Optional 3) :</p>
                    <p className={'company-detail-des'}>{ company.industry4 }</p>
                </div>}
                {company.keyValueProposition && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Key Value Proposition :</p>
                    <p className={'company-detail-des'}>{ company.keyValueProposition }</p>
                </div>}
                {company.productMaturity && <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Product Maturity :</p>
                    <p className={'company-detail-des'}>{ company.productMaturity }</p>
                </div>}
                <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Investment Types :</p>
                    <p className={'company-detail-des'}>{company.businessStages && company.businessStages.map((type, idx) => {
                        return(
                                <div key={idx} className="company-detail-investment">
                                    <span>{ type }</span>
                                </div>
                            )
                        }) }
                    </p>
                </div>
                <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Stakeholder Involvement :</p>
                    <p className={'company-detail-des'}>{company.stakeholders && company.stakeholders.map((stakeholder, idx) => {
                        return(
                            <div key={idx} className="company-detail-investment">
                                <span>{stakeholder.role} - {stakeholder.type}</span>
                            </div>
                        )
                    }) }
                    </p>
                </div>
                <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Export Market Share :</p>
                    <p className={'company-detail-des'}>{company.marketPrecences && company.marketPrecences.map((market,idx) => {
                        return(
                                <div key={idx} className="company-detail-market">
                                    <span>{ market.region } - { market.percentage }%</span>
                                </div>
                            )
                        }) }
                    </p>
                </div>
                <div className={'company-detail-row'}>
                    <p className={'company-detail-title'}>Collateral :</p>
                    <p className={'company-detail-des'}>{company.collaterals && company.collaterals.map((collateral, index) => {
                        return(
                                <div key={index} className="company-detail-link">
                                    <a href={collateral} download={`Collateral_${index + 1}_${collateral.split('/').pop()}`}
                                       target="_blank" rel="noopener noreferrer">
                                        Collateral {index + 1}
                                    </a>
                                </div>
                            )
                        }) }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SingleCompanyDetailScreen;