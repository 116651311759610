import React from "react";
import {Input, InputNumber} from "antd";

import './CustomTextInputInline.css';

const CustomTextInputInline = ({ title, htmlFor, id, inputValue, setValue, isPassword = false, placeholder,
                              isUrl = false, isNormal = false, isNumber = false, isPrice = false,
                                   large = false, isOther = false, isDisable = false,
                                   isErrorMessage = false, errorMessage = '', isPasswordReset = false,
                                   isCustomSize = false }) => {

    return(
        <div className={large ? 'text-input-container-large' :'text-input-container'}>
            {!isOther && <div className="label-container">
                <label htmlFor={ htmlFor } className={`cus-label ${isPasswordReset && 'password-reset-label'}`}>
                    { title }
                </label>
            </div>}
            <div className={`${isCustomSize && 'input-container-custom'} ${large ? "input-container-large" : "input-container"} ${isOther ? 'input-container-other' : ''}`}>
                { isUrl &&  <Input id={ id } value={inputValue} placeholder={placeholder}
                                   onChange={setValue} className="cus-input" addonBefore="https://"/> }
                { isPassword && <Input.Password id={ id } value={inputValue} placeholder={placeholder}
                                                onChange={setValue} className="cus-input"/> }
                { isNormal && <Input id={ id } value={inputValue} placeholder={placeholder}
                                     onChange={setValue} className="cus-input" disabled={isDisable}/> }
                { isNumber && <InputNumber id={ id } defaultValue={0} min={0} value={inputValue} placeholder={placeholder}
                                           onChange={setValue} className={'cus-input'}/> }
                { isPrice && <InputNumber id={ id } defaultValue={0} min={0} value={inputValue} placeholder={placeholder}
                                          onChange={setValue} prefix="$" className={'cus-input'}/> }
                { isErrorMessage && <p className="error-message">{ errorMessage }</p> }
            </div>
        </div>
    )
}

export default CustomTextInputInline;