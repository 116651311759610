import React, { useState } from "react";
import { Checkbox, message } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from 'react-redux';

import CustomButton from "../../components/common/button/CustomButton";
import CustomTextInput from "../../components/common/textInput/CustomTextInput";

import { loginUser } from '../../store/actions/LoginAction';

import { DEV_URL } from '../../configuration/config';

import './LoginScreen.css';

import logo from '../../assets/common/logo-new-1.png';

const LoginScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const [isUsernameError, setIsUsernameError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);

    const handleUsername = (e) => {
        setUsername(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = async () => {
        setIsUsernameError(false);
        setIsPasswordError(false);

        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        const emailValid = emailRegex.test(username);
        const passwordValid = password.trim().length > 5;

        if(!emailValid){
            setIsUsernameError(true);
        }
        if(!passwordValid){
            setIsPasswordError(true);
        }

        localStorage.removeItem('user');

        if(emailValid && passwordValid){
            try{
                const { data } = await axios.post(`${DEV_URL}/api/auth/login`,{
                    email: username,
                    password: password
                });
                message.success(data.message);

                if(data.user){
                    localStorage.setItem('user', JSON.stringify(data.user));
                }
                dispatch(loginUser(data.user, data.token));
                navigate('/');

            }catch (err){
                console.log(err.message);
                message.error('Authentication error !!!');
            }
        }else{
            message.error('Please check entered data again')
        }
    }

    return(
        <div className="login-page">
            <div className={'login-container'}>
                <div className={'section-1'}>
                    <div className={'login-page-intro-box'}>
                        <img className={'image'} src={logo} alt={'logo'}/>
                        <div>
                            <p className={'login-page-intro-box-header'}>Digiecon</p>
                            <p className={'login-page-intro-box-header'}>Global Investment</p>
                            <p className={'login-page-intro-box-header'}>Summit 2024</p>
                        </div>
                        <div className={'login-page-intro-box-body-container'}>
                            <p className={'login-page-intro-box-body-title'}>25th June 2024</p>
                            <p className={'login-page-intro-box-body-title'}>Cinnamon Grand, Colombo</p>
                            <p className={'login-page-intro-box-body-desc'}>A joint industry initiative connecting Sri Lankan startup
                                and scale-up companies with investors, to support their next growth phase & global expansion</p>
                        </div>
                    </div>
                </div>
                <div className={'section-2'}>
                    <div className="login-form">
                        <p className={'login-header'}>LOGIN</p>
                        <CustomTextInput inputValue={username} setValue={handleUsername} placeholder={'Username'}/>
                        {isUsernameError && <p className="login-error-message">Enter valid Email</p>}

                        <CustomTextInput inputValue={password} setValue={handlePassword} placeholder={'Password'} isPassword={true}/>
                        {isPasswordError && <p className="login-error-message">Enter valid Password</p>}

                        <Checkbox checked={rememberMe} className="custom-checkbox"
                                  onChange={(e) => setRememberMe(e.target.checked)}>Remember me</Checkbox>

                        <CustomButton buttonClick={handleSubmit} title={'Login'} color={'#000000'}/>

                        <p className="link-disable">
                            <Link to="/forgot-password" className="cus-link">Forgot Password</Link>
                        </p>
                        <p className={'cus-link'}>
                            Don't have an account? <Link to="/sign-up" className="login-link">Sign Up!</Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className={'login-page-footer'}>
                {/*<div className={'login-page-logo-container'}>
                    <Image width={130} height={60} preview={false} src={companyLogo}/>
                </div>*/}
                <div className={'login-page-footer-image'}></div>
                <p className={'login-powered-test'}>Powered By Engenuity Ai</p>
            </div>
        </div>
    )
}

export default LoginScreen;