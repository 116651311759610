import React, {useEffect, useState} from "react";
import { Upload, Button, message, Select, Tooltip, Input } from 'antd';
import {PlusCircleOutlined, UploadOutlined} from '@ant-design/icons';

import './CompanyRegisterForm.css'

const { TextArea } = Input;
const { Option } = Select;

const productIndustryList = ['HealthTech', 'EduTech', 'AgriTech', 'FinTech', 'LogisticsTech (LogiTech)',
    'GreenTech', 'E-commerce/m-commerce', 'Life Sciences', 'IoT', 'Artificial Intelligence / Machine Learning',
    'Blockchain', 'Robotics', 'Big Data', 'Other'
];
const workType = ["Full-Time" , "Part-Time"];
const roleType = ["Investor", "Promoter", "Technical"];

const CompanyRegisterFormPart4 = ({ onChange, formData, isFieldError }) => {
    const [stakeholders, setStakeholders] = useState([{ type: '', role: '' }]);

    useEffect(() => {
        if(formData.stakeholders.length > 0){
            setStakeholders(formData.stakeholders);
        }
    },[]);

    const handleKeyValueProposition = (e) => {
        onChange('keyValueProposition', e.target.value)
    }

    const handleStakeHolderType = (index, type) => {
        const updateStakeholders = [...stakeholders];
        updateStakeholders[index].type = type;
        setStakeholders(updateStakeholders);
    }

    const handleStakeHolderRole = (index, type) => {
        const updateStakeholders = [...stakeholders];
        updateStakeholders[index].role = type;
        setStakeholders(updateStakeholders);
    }

    const addStakeHolder = () => {
        const updateStakeholders = [...stakeholders];
        updateStakeholders.push({ type: '', role: '' });
        setStakeholders(updateStakeholders);
    }

    const addStakeHolderToState = () => {
        onChange('stakeholders', stakeholders);
    }

    const checkFileSize = (file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 15) {
            message.error('File size must be within 15MB!');
            return false;
        }
        return true;
    };

    const customCollateralsRequest = ({ file, onSuccess, onError }) => {
        const isValid = checkFileSize(file);
        if (isValid) {
            const files = [...formData.collaterals, file];
            onChange('collaterals', files);
            onSuccess();
        } else {
            onError();
        }
    };

    const handleProductIndustry1 = (type) => {
        onChange('companyIndustry1', type)
    }

    const handleProductIndustry2 = (type) => {
        onChange('companyIndustry2', type)
    }

    const handleProductIndustry3 = (type) => {
        onChange('companyIndustry3', type)
    }

    const handleProductIndustry4 = (type) => {
        onChange('companyIndustry4', type)
    }

    return(
        <div>
            <div className={'register-upload-container'}>
                <div className={'register-button-label'}>
                    <label htmlFor={'collaterals'} className="cus-label" style={{textAlign: 'left'}}>
                        Collateral
                    </label>
                </div>
                <div className={'upload-button-input-container'}>
                    <div className={'upload-button-container'}>
                        <Upload
                            customRequest={customCollateralsRequest}
                            multiple={true}
                            maxCount={5}
                            beforeUpload={checkFileSize}>
                            <Button icon={<UploadOutlined/>} className={'upload-button'}>Upload Files</Button>
                        </Upload>
                        <div>
                            <p className={'upload-text'}>Max file size (15MB)</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'register-text-area-container'}>
                <label htmlFor={'keyValueProposition'} className="cus-label">
                    Key Value Proposition
                </label>
                <div className={'register-text-area-input-container2'}>
                    <TextArea className={'register-text-area'} rows={3} value={formData.keyValueProposition}
                              placeholder={'50 Words'} onChange={handleKeyValueProposition}/>
                    {isFieldError.isKeyValuePropositionError &&
                        <p className="error-message">Enter valid Key Value Proposition</p>}
                </div>
            </div>
            <div className={'register-stakeholder-container'}>
                <label htmlFor={'country'} className="cus-label">
                    Stakeholder Involvement in Company
                </label>
                <div style={{marginTop: '10px'}}>
                    {stakeholders.map((stakeholder, index) => {
                        return (
                            <div key={index} className={'stakeholder-container'}>
                                <p className={'stakeholder-text'}>{index + 1}.</p>
                                <Tooltip title="Stakeholder type" color='#264294' placement="bottom">
                                    <Select className={'stakeholder-selector'} size={'large'} value={stakeholder.type}
                                            onChange={(type) => handleStakeHolderType(index, type)}
                                            placeholder={'Full-Time / Part-Time'} onBlur={addStakeHolderToState}>
                                        {workType.map(work => {
                                            return <Option value={work} key={work}>{work}</Option>
                                        })}
                                    </Select>
                                </Tooltip>
                                <Tooltip title="Stakeholder role" color='#264294' placement="bottom">
                                    <Select className={'stakeholder-selector'} size={'large'} value={stakeholder.role}
                                            onChange={(type) => handleStakeHolderRole(index, type)}
                                            placeholder={'Role'} onBlur={addStakeHolderToState}>
                                        {roleType.map(role => {
                                            return <Option value={role} key={role}>{role}</Option>
                                        })}
                                    </Select>
                                </Tooltip>
                            </div>
                        )
                    })}
                </div>
                <div className={'investor-addButton'} onClick={addStakeHolder}>
                    <PlusCircleOutlined style={{fontSize: '28px', color: 'white'}}/>
                </div>
            </div>

            <div className={'investment-industry-preference-container'}>
                <div className={'investment-industry-preference-label'}>
                    <label htmlFor={'investmentType'} className="cus-label" style={{textAlign: 'left'}}>
                        Industry
                    </label>
                </div>
                <div className={'investment-select-container'}>
                    <div className={'investment-select-input-container'}>
                        <Tooltip title="Select Primary Industry" color='#264294' placement="bottom">
                            <Select style={{width: '100%'}} value={formData.companyIndustry1}
                                    onChange={handleProductIndustry1} size={'large'}
                                    placeholder={'Select Primary Industry'}>
                                {productIndustryList.map(industry => {
                                    return <Option value={industry} key={industry}>{industry}</Option>
                                })}
                            </Select>
                        </Tooltip>
                        {isFieldError.isProductIndustry1Error && <p className="error-message">Select valid Industry</p>}
                    </div>
                    <div className={'investment-select'}>
                        <Tooltip title="Select Industry (Optional)" color='#264294' placement="bottom">
                            <Select style={{width: '100%'}} value={formData.companyIndustry2}
                                    onChange={handleProductIndustry2} size={'large'}
                                    placeholder={'Select Industry (Optional)'}>
                                {productIndustryList.map(industry => {
                                    return <Option value={industry} key={industry}>{industry}</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </div>
                    <div className={'investment-select'}>
                        <Tooltip title="Select Industry (Optional)" color='#264294' placement="bottom">
                            <Select style={{width: '100%'}} onChange={handleProductIndustry3}
                                    size={'large'} value={formData.companyIndustry3}
                                    placeholder={'Select Industry (Optional)'}>
                                {productIndustryList.map(industry => {
                                    return <Option value={industry} key={industry}>{industry}</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </div>
                    <div className={'investment-select'}>
                        <Tooltip title="Select Industry (Optional)" color='#264294' placement="bottom">
                            <Select style={{width: '100%'}} onChange={handleProductIndustry4} size={'large'}
                                    placeholder={'Select Industry (Optional)'} value={formData.companyIndustry4}>
                                {productIndustryList.map(industry => {
                                    return <Option value={industry} key={industry}>{industry}</Option>
                                })}
                            </Select>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyRegisterFormPart4;