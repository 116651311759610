import React from 'react';

import './DashboardTypeSelectButton.css';

const DashboardTypeSelectButton = ({ title, selected }) => {
    return(
        <div className={`dashboard-type-select-button ${selected ? 'dashboard-type-select-button-selected' : 
            'dashboard-type-select-button-not-selected'}`}>
            <p className={`dashboard-type-select-button-text ${selected ? 'dashboard-type-select-button-text-selected': 
                'dashboard-type-select-button-text-not-selected'}`}>{title}</p>
        </div>
    )
}

export default DashboardTypeSelectButton;