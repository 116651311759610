import React, { useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { message } from "antd";
import axios from "axios";

import CustomButton from "../../components/common/button/CustomButton";
import CustomTextInputInline from "../../components/common/textInput/CustomTextInputInline";

import { DEV_URL } from '../../configuration/config';

import './SignUpScreen.css';

import logo from '../../assets/common/logo-new-1.png';

const SignUpScreen = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const type = searchParams.get('type');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [displayName, setDisplayName] = useState('');

    const [isFieldsError, setIsFieldsError] = useState({
        isEmailError: false,
        isPasswordError: false,
        isConfirmPasswordError: false,
        isCompanyNameError: false,
        isContactNumberError: false,
        isDisplayNameError: false
    });

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const handleCompanyName = (e) => {
        setCompanyName(e.target.value);
    }

    const handleContactNumber = (e) => {
        const { value } = e.target;
        if (/^[0-9+]*$/.test(value)) {
            setContactNumber(value);
        }
    }

    const handleDisplayName = (e) => {
        setDisplayName(e.target.value);
    }

    const signUpClickHandler = async () => {
        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        const mobileNumberRegex = /^\+?[0-9]*$/;

        const isEmailValid = emailRegex.test(email);
        const isMobileNumberValid = mobileNumberRegex.test(contactNumber) && contactNumber.trim().length > 8;
        const passwordValid = password.trim() === confirmPassword.trim() && password.trim().length > 5;
        const displayNameValid = displayName.trim().length > 3;
        const companyNameValid = companyName.trim().length > 1;

        setIsFieldsError({
            isEmailError: !isEmailValid,
            isPasswordError: !passwordValid,
            isConfirmPasswordError: !passwordValid,
            isCompanyNameError: !companyNameValid,
            isContactNumberError: !isMobileNumberValid,
            isDisplayNameError: !displayNameValid
        });

        if(isEmailValid && isMobileNumberValid && passwordValid && displayNameValid && companyNameValid){
            let requestBody = {
                email: email,
                password: password,
                companyName: companyName,
                contactNumber: contactNumber,
                displayName: displayName
            };

            message.success('valid');

            if(type === 'company'){
                // signup as company
                requestBody.type = 1;

            }else if(type === 'investor'){
                // signup as investor
                requestBody.type = 2;
            }
            try{
                const response = await axios.post(`${DEV_URL}/api/auth/signup`, requestBody);
                if(response.data.status == 200){
                    message.error(response.data.message);
                }else{
                    message.success(response.data.message);
                    navigate('/log-in');
                }

            }catch (err){
                console.error(err.message);
                message.error(err.message);
            }
        }else{
            message.error('Please check entered data again');
        }
    }

    const disclaimerHandler = () => {
        if(type === 'company'){
            //navigate('/disclaimer?type=company');
            window.open('/disclaimer?type=company', '_blank');
        }else if(type === 'investor'){
            //navigate('/disclaimer?type=investor')
            window.open('/disclaimer?type=investor', '_blank');
        }
    }

    return(
        <div className={'signup-as-page'}>
            <div className={'signup-image-container'}>
                <img src={logo} className={'signup-image'} alt={'logo'}/>
            </div>
            <div className={'signup-as-page-body'}>
                <div className={'signup-info-container'}>
                    <div className={'signup-page-title-container'}>
                        <p className={'signup-page-title'}>SIGN UP</p>
                    </div>
                    <div className={'signup-page-description-container'}>
                        <p className={'signup-page-description-text'}>Please Fill In the Necessary Information in the
                            Relevant Fields</p>
                    </div>
                    <div className={'signup-field-box'}>
                        <CustomTextInputInline inputValue={email} placeholder={'Enter your Email'} isNormal={true}
                                               setValue={handleEmail} title={'Email'} id={'email'} htmlFor={'email'}
                                               isErrorMessage={isFieldsError.isEmailError}
                                               errorMessage={'Enter valid Email'}/>
                        <CustomTextInputInline inputValue={password} placeholder={'Enter your Password'}
                                               isPassword={true}
                                               setValue={handlePassword} title={'Password'} id={'password'}
                                               htmlFor={'password'}
                                               isErrorMessage={isFieldsError.isPasswordError}
                                               errorMessage={'Enter a valid password (Password must contain at least 6 characters)'}/>
                        <CustomTextInputInline inputValue={confirmPassword} placeholder={'Enter your Confirm Password'}
                                               isPassword={true} setValue={handleConfirmPassword}
                                               title={'Confirm Password'}
                                               id={'confirmPassword'} htmlFor={'confirmPassword'}
                                               isErrorMessage={isFieldsError.isConfirmPasswordError}
                                               errorMessage={'Enter a valid password (Password must contain at least 6 characters)'}/>
                        <CustomTextInputInline inputValue={companyName} placeholder={'Enter your Company Name'}
                                               isNormal={true}
                                               setValue={handleCompanyName} title={'Company Name'} id={'companyName'}
                                               htmlFor={'companyName'} isErrorMessage={isFieldsError.isCompanyNameError}
                                               errorMessage={'Enter valid Company Name'}/>
                        <CustomTextInputInline inputValue={contactNumber} placeholder={'Enter your Contact Number'}
                                               isNormal={true}
                                               setValue={handleContactNumber} title={'Contact Number'}
                                               id={'contactNumber'} htmlFor={'contactNumber'}
                                               isErrorMessage={isFieldsError.isContactNumberError}
                                               errorMessage={'Enter valid Contact Number'}/>
                        <CustomTextInputInline inputValue={displayName} placeholder={'Enter your Display Name'}
                                               isNormal={true}
                                               setValue={handleDisplayName} title={'Display Name'}
                                               id={'displayName'} htmlFor={'displayName'}
                                               isErrorMessage={isFieldsError.isDisplayNameError}
                                               errorMessage={'Enter valid Display Name'}/>
                        <div className={'signup-button-container'}>
                            <CustomButton title={'Submit'} color={'#000000'} buttonClick={signUpClickHandler}/>
                        </div>
                    </div>
                </div>
                <div className={'disclaimer-link-container'}>
                    <div></div>
                    <p className={'disclaimer-link'} onClick={disclaimerHandler}>Disclaimer</p>
                </div>
            </div>
        </div>
    )
}

export default SignUpScreen;