import React from 'react';
import { Modal } from "antd";

import './InvestorAccpetMeetingModel.css'

const InvestorRejectMeetingModal = ({ isOpen, clickOk, clickCancel, note, changeNote }) => {
    return(
        <Modal title="MEETING REQUESTS" open={isOpen} onOk={clickOk} onCancel={clickCancel} className={'custom-model'}
               okButtonProps={{className: 'custom-model-ok-button'}}>
            <div>
                    <textarea value={note} onChange={changeNote} placeholder={'* Note to Company '}
                              className={'new-schedule-note'}>
                    </textarea>
            </div>
        </Modal>
    )
}

export default InvestorRejectMeetingModal;